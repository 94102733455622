/*-------------------------------------------------------------------
    파일정의 : Settings
    생성일자 : 2020-06-30 mangojang
-------------------------------------------------------------------*/
@import 'reset_custom';

/*-------------------------------------------------------------------
    Globals
-------------------------------------------------------------------*/
@padding: 20px;
@maxWidth: 1410px + @padding*2;
@maxWidth02: 1230px;
@maxWidth03: 930px;
@maxWidth04: 750px;
@maxWidth05 : 990px;
@minWidth: 1200px + @padding;
@mainSectionBottom : 96px;
@headerH: 267px;
@listGap01: 2.1%;
@listGap02: 3.3%;


.d_only{
    display: block !important;
}
.dt_only{
    display: block !important;
}
.m_only{
    display: none !important;
}

.hover-vertical-line(@height){
    position: relative;
    &::after{
        content: '';
        display: block;
        width: 0;
        height: @height;
        background-color: #fff;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        transition: width .2s ease-in;
    }
    &.on{
        &::after{
            width: 100%;
        }
    }
    &:hover{
        .on;
    }
}

.wrap01{
    max-width: @maxWidth;
    min-width: @minWidth;
    margin: 0 auto;
    padding: 0 @padding;
    .afters;
}
.wrap02{
    max-width: @maxWidth02;
    margin: 0 auto;
    .afters;
}
.wrap03{
    max-width: @maxWidth03;
    margin: 0 auto;
    .afters;
}
.wrap04{
    max-width: @maxWidth04;
    margin: 0 auto;
    .afters;
}
.wrap05{
    .wrap03;
}
.wrap06{
    max-width: @maxWidth05;
    margin: 0 auto;
}

.navigationBtnCommon{
    width: 40px;
    height: 40px;
    border:@border-pointColor;
    .bgo;
    background-color: #fff;
    background-size: 8px;
    cursor: pointer;
    &.swiper-button-disabled,&.disabled{
        border: @border01;
    }
}
.navigationPrev{
    background-image: url(/asset/images/btn-arrow-onlyline-prev.png);
    &:focus,&.active{
        outline: none;
    }
    &.swiper-button-disabled,&.disabled{
        background-image: url(/asset/images/icn-w-pagination-line-arrow-prev-dis.png);
    }
}
.navigationNext{
    background-image: url(/asset/images/btn-arrow-onlyline-next.png);
    &:focus,&.active{
        outline: none;
    }
    &.swiper-button-disabled,&.disabled{
        background-image: url(/asset/images/icn-w-pagination-line-arrow-next-dis.png);
    }
}
.navigationPrevFirst{
    background-image: url(/asset/images/icn-w-pagination-line-arrow-first.png);
    background-size: 13px;
    &:focus,&.active{
        outline: none;
    }
    &.swiper-button-disabled,&.disabled{
        background-image: url(/asset/images/icn-w-pagination-line-arrow-first-dis.png);
    }
}
.navigationNextLast{
    background-image: url(/asset/images/icn-w-pagination-line-arrow-last.png);
    background-size: 13px;
    &:focus,&.active{
        outline: none;
    }
    &.swiper-button-disabled,&.disabled{
        background-image: url(/asset/images/icn-w-pagination-line-arrow-last-dis.png);
    }
}

@pvts-fontsize: 16px;
@pvts-lineheight: 1.75;
@pvts-tbordercolor: @grey01;
@pvts-tbgcolor: @bgColor01;
@pvts-tpadding: 24px 12px;
.post_view_txt_section{
    p,span,strong,b,u,a,i,div{
        line-height: @pvts-lineheight;
        font-weight: 500;
        font-size: @pvts-fontsize;
    }
    strong,b{
        font-weight: bold;
    }
    a{
        text-decoration: underline;
    }
    img,iframe,video{
        max-width: 100%;
    }
    table{
        width: 100%;
        margin: 10px 0;
        table-layout: fixed;
        th,td{
            font-size: @pvts-fontsize;
            border: 1px solid @pvts-tbordercolor;
            padding: @pvts-tpadding;
            word-break: keep-all;
            font-weight: 500;
            text-align: center;
        }
        th{
            font-weight: bold;
            background-color: @pvts-tbgcolor;
            p,span,strong,b,u,a,i,div{
                font-size: inherit;
                font-weight: inherit;
                word-break: keep-all;
                text-align: inherit;
            }
            strong,b{
                font-weight: bold;
            }
        } 
        td{
            line-height: @pvts-lineheight;
            p,span,strong,b,u,a,i,div{
                font-size: inherit;
                font-weight: inherit;
                line-height: inherit;
                word-break: keep-all;
                text-align: inherit;
            }
            strong,b{
                font-weight: bold;
            }
        }
    }
    //editor class
    .note-float-right,.note-float-left{
        margin: 10px;
    }
}

.badge_box{
    .badge{
        &.play{ //공연
            background-color: @play;
        }
        &.exhibition{ //전시
            background-color: @display;
        }
        &.event{ //행사
             background-color: @event;
        }
        &.education{ //교육
            background: @education;
        }
        &.news{ //소식
            background: @news;
        }
        &.important{ //중요
            background-color: @important;
        }
        &.ongoing{ //ongoing
            background-color: @important;
            .eFont;
        }
        &.progress{ //진행
            background-color: @progress;
        }
        &.expected{ //예정
            background-color: @expected;
        }
        &.end{ //종료
            background-color: @end;
        } 
    }
}

.no_result_case{
                margin: 52px 0 78px;
                .guide_box{
                    max-width: 870px;
                    margin: 0 auto;
                    border: @border02;
                    padding: 96px 90px 96px 80px;
                    .afters;
                    .left,.right{
                        float: left;
                    }
                    .left{
                        width: 33.6%;
                        .icon{
                            width: 132px;
                            height: 144px;
                            .bgo;
                            background-image: url(/asset/images/img-search-nodata.png);
                        }
                    }
                    .right{
                        width: 66.4%;
                        .g_title{
                            font-size: 26px;
                            font-weight: 500;
                            line-height: 1.23;
                            word-break: keep-all;
                            margin-bottom: 24px;
                            .word{
                                font-size: inherit;
                                font-weight: bold;
                                line-height: inherit;
                                color: @pointColor;
                                &::before{
                                    content: '‘';
                                    font-size: inherit;
                                    font-weight: inherit;
                                    color: inherit;
                                }
                                &::after{
                                    content: '’';
                                    font-size: inherit;
                                    font-weight: inherit;
                                    color: inherit;
                                }
                            }
                        }
                        .list01{
                            li{
                                font-size: 15px;
                                &::before{
                                    top: 10px;
                                }
                            }
                        }
                    }
                }
            }

/*-------------------------------------------------------------------
    Plugin
-------------------------------------------------------------------*/
.swiper-container{
    padding-bottom: 80px;
    .swiper-pagination{
        width: auto;
        left: 50%;
        transform: translateX(-50%);
        .swiper-pagination-bullet{
            width: 14px;
            height: 14px;
            border: 1px solid white;
            background-color: white;
            opacity: 1;
            position: relative;
            &:after{
                content: '';
                display: block;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: @pointColor;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%);
            }
            &.swiper-pagination-bullet-active{
                border: @border-pointColor;
            }
        }
    }
}

.sld_navigation{
    width: 270px;
    .afters;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    z-index: 1;
    .btn_sld_prev,.btn_sld_next{
        .navigationBtnCommon;
    }
    .btn_sld_prev{
        float: left;
        .navigationPrev
    }
    .btn_sld_next{
        float: right;
        .navigationNext
    }
}
.sld_next,.sld_prev{
    .navigationBtnCommon
}
.sld_next{
    .navigationNext;
}
.sld_prev{
    .navigationPrev;
}

.ui-datepicker{
    width: 18em;
    border-radius: 0;
    border: 1px solid #e8e8e8;
    padding: 0;
    .ui-widget-header{
        border: none;
        border-radius: 0;
        background-color: white;
        padding: 0.813em 0.75em;
        background-color: @pointColor;
        .ui-datepicker-title{
            span{
                .eFont;
                font-size: 1.5em;
                color: white;
            }
        }
        .ui-datepicker-next,.ui-datepicker-prev{
            border: @border-pointColor;
            border-radius: 0;
            .bgo;
            background-size: 6px;
            background-color: white;
            width: 2.5em;
            height: 2.5em;
            top: 0.5em;
            span{
                .bld;
            }
        }
        .ui-datepicker-next{
            .navigationNext;
            right: 0.75em;
        }
        .ui-datepicker-prev{
            .navigationPrev;
            left: 0.75em;
            
        }
    }
    .ui-datepicker-calendar{
        width: calc(100% - 0.625em*2);
        margin: 0.625em;
        thead{
            th{
                background-color: @grey03;
                padding: .4em .3em;
                span{
                    display: block;
                    font-size: 0.813em;
                    .eFont;
                    color: @pointColor;
                    text-transform: uppercase;
                    line-height: 1;
                }
                span[title="Sunday"]{
                    color: @red01;
                }
                span[title="Saturday"]{
                    color: @blue01;
                }
            }
        }
        tbody{
            td{
                .ui-state-default{
                    width: 1.75em;
                    height: 1.75em;
                    border: none;
                    background-color: #fff;
                    .eFont;
                    text-align: center;
                    padding: 0.375em 0;
                    margin: 0 auto;
                    line-height: 1;
                    &.ui-state-highlight{
                        background-color: inherit;
                        color: inherit;
                    }
                    &.ui-state-active{
                        background-color: @pointColor;
                        color: white !important;
                        border-radius: 50%;
                    }
                }
                &.ui-datepicker-week-end{
                    &:first-child{
                        .ui-state-default{
                            color: @red01;
                        }
                    }
                    &:last-child{
                        .ui-state-default{
                            color: @blue01;
                        }
                    }
                } 
            }
        }
    }
}

.mfp-bottom-bar{
    .bld;
}
.mfp-arrow{
    &::after{
        border: none;
        width: 36px;
        height: 36px;
        .bgo;
        background-size: 20px;
    }
}
.mfp-arrow-right{
    &::after{
        background-image: url(/asset/images/btn-w-pagination-line-arrow-white-next-act.png);
    }
}

.mfp-arrow-left{
    &::after{
        background-image: url(/asset/images/btn-w-pagination-line-arrow-white-prev-act.png);
    }
}
.mfp-close{
    &.btn_close{
        width: 20px;
        height: 20px;
        padding-right: 0;
        right: 0;
        top: 10px;
        background: url(/asset/images/icn-menu-close-white.png) no-repeat center/18px;
    }
}
/*-------------------------------------------------------------------
    Components
-------------------------------------------------------------------*/
/*inputs*/
input[type="text"]{
    &::placeholder{
        font-weight: inherit;
        font-size: inherit;
        color: inherit;
    }
}
.select_box01{
    width: 100%;
    height: 40px;
    padding: 0 26px;
    background: @grey03 url(/asset/images/object-arrow-down.png) no-repeat right 22px center/14px;
    .eFont;
    color: @pointColor;
    option{
        background-color: white;
    }
    &:active{
        // background: red url(/asset/images/object-arrow-down.png) no-repeat right 22px center/14px;
    }
}
.select_box02{
    width: 100%;
    height: 48px;
    font-size: 20px;
    font-weight: 300;
    color: @pointColor;
    background:#fff url(/asset/images/object-arrow-down.png) no-repeat right 20px center/14px;
    padding: 0 20px;
}
.check_box{
    input[type="checkbox"] {
        display: none;
    }
    input[type="checkbox"]+label span {
        .inline-block;
        width: 14px;
        height: 14px;
        margin: -2px 0px 0 0;
        vertical-align: -3px;
        border-radius: 50%;
        background-color: #fff;
        border: @border-pointColor;
        cursor: pointer;
    }
    input[type="checkbox"]:checked+label span {
        background: @pointColor url(/asset/images/icn-check-checked-14-px.png) no-repeat center;
    }
    >span {
        font-size: 10px;
        font-weight: bold;
        color: @pointColor;
    }
}
.check_box02{
    font-size: 0;
    input[type="checkbox"] {
        display: none;
    }
    input[type="checkbox"]+label span {
        .inline-block;
        width: 18px;
        height: 18px;
        margin: -2px 5px 0 0;
        vertical-align: -4px;
        border-radius: 50%;
        background:#fff url(/asset/images/icn-check-unchecked-18-px.png) no-repeat center/10px;
        border: 1px solid #d8d8d8;
        cursor: pointer;
    }
    input[type="checkbox"]:checked+label span {
        border: @border-pointColor;
        background-image: url(/asset/images/icn-check-checked-14-px.png);
        background-color: @pointColor;
    }
    >span {
        font-size: 12px;
        font-weight: 300;
        letter-spacing: -.3px;
        color: @textColor01;
        
    }
    &.big{
        input[type="checkbox"]+label span {
            width: 24px;
            height: 24px;
            background-size: 13px;
            vertical-align: -5px;
            margin: -2px 5px 0 0;
        }
        >span{
            font-size: 16px;
            font-weight: bold;
            color: @pointColor;
            .cmt{
                font-size: 14px;
                font-weight: inherit;
                color: inherit;
            }
        }
    }
}
.input_box01{
    border: @border03;
    background-color: @bgColor01;
    height: 40px;
    input,select{
        width: 100%;
        height: 100%;
        padding: 0 15px;
        background-color: @bgColor01;
        font-size: 15px;
        font-weight: 300;
        color: @pointColor;
        &::placeholder{
            color: @grey02;
        }
    }
    select{
        padding: 0 44px 0 15px;
        background: @bgColor01 url(/asset/images/object-arrow-down.png) no-repeat right 15px center/14px;
        option{
            background-color: white;
        }
    }
    &.datepicker{
        input{
            padding: 0 44px 0 15px;
            background: @bgColor01 url(/asset/images/icn-calandar-act.png) no-repeat right 15px center/14px;
            color: @pointColor;
        }
    }
    &.width_button{
        padding-right: 138px;
        position: relative;
        button, label{
            position: absolute;
            top: -1px;
            right: 0;
        }
    }
    &.homepage{
        input{
            color: @textColor01;
        }
    }
}
.file_upload_box{
    .afters;
    .input_box01{
        float: left;
        width: 100%;
        margin-bottom: 8px;
        &:last-child{
            margin-bottom: 0;
        }
        input[type="file"]{
            .bld;
        }
    }
    .button_box{
        float: left;
        .bld;
        width: 40px;
        height: 40px;
        position: relative;
        button{
            display: block;
            width: 20px;
            height: 20px;
            background-color: @pointColor;
            .bgo;
            background-size: 10px;
            font-size: 0;
            border-radius: 50%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            cursor: pointer;
            &.btn_refresh{
                background-image: url(/asset/images/icn-write-attachfile-refresh.png);
            }
            &.btn_delete{
                background-image: url(/asset/images/icn-write-attachfile-delete.png);
            }
        }
    }
    &.show{
        .input_box01{
            width: calc(100% - 40px);
        }
        .button_box{
            display: block;
        }
    }
}
.option_box{
    border: 1px solid @grey03;
    background-color: @bgColor01;
    padding: 0 15px;
    font-weight: 300;
    text-align: left;
    font-size: 15px;
    &:active,&:focus{
        border: @border-pointColor;
    }
    &.select_date{
        width: 100%;
        color: @pointColor;
        .eFont;
        padding: 0 43px 0 15px;
        background: @bgColor01 url(/asset/images/icn-calandar-act.png) no-repeat right 15px center/14px;
        height: 40px;
        &::placeholder{
           font-weight: 300;
           .fontA;
           color: inherit;
        }
        &:-ms-input-placeholder{
            font-weight: 300 !important;
           .fontA;
           color: @pointColor!important;
        }
    }
    &.select_options{
        .fontA;
        background: @bgColor01 url(/asset/images/object-arrow-down.png) no-repeat right 15px center/14px;
    }
    &.select_search{
        width: 334px;
        height: 40px;
        &::placeholder{
            color: @grey01;
        }
    }
}

/*buttons*/
.btn_read_more{
    padding: 3px 4px;
    .inline-block;
    position: relative;
    &::before{
        content: '';
        display: block;
        width: 0;
        height: 100%;
        background-color: @pointColor;
        position: absolute;
        left: 0;
        top: 0;
        transition: width .2s ease-in;
    }
    .inner{
        position: relative;
        z-index: 1;
        .inline-block;
        border-bottom: @border-pointColor;
        padding-bottom: 2px;
        transition: border-bottom .2s ease-in;
        span{
            .eFont;
            font-size: 13px;
            color: @pointColor;
            transition: color .2s ease-in;
            &::after{
                content: '';
                display: inline-block;
                width: 12px;
                height: 12px;
                .bgo;
                background-size: 100%;
                background-image: url(/asset/images/icn-readmore-line-arrow.png);
                margin-left: 5px;
                transition: background-image .2s ease-in;
            }
        }
        
    }
}
.btn_read_more_hover{
    // background-color: @pointColor;
    &::before{
        width: 100%;
    }
    .inner{
        border-bottom: 1px solid white;
        span{
            color: white;
            &::after{
                background-image: url(/asset/images/icn-readmore-line-arrow-white.png);
            }
        }
    }
}
.btn_map{
    padding: 4px 9px;
    background-color: @pointColor;
    border:  @border-pointColor;
    span{
        font-size: 12px;
        font-weight: bold;
        color: white;
        &::after{
            content: '';
            .inline-block;
            width: 5px;
            height: 5px;
            .bgo;
            background-image: url(/asset/images/icn-btn-outlink.png);
            margin-left: 3px;
            vertical-align: 5px;
        }
    }
    &:hover{
        span{
            &::after{
                transform: translate(2px,-2px);
            }
        }
    }
}
.btn_culture_event_view{
    .inline-block;
    padding: 4px 20px 4px 10px;
    border: @border-pointColor;
    span{
        font-size: 12px;
        font-weight: bold;
        color: @pointColor;
        position: relative;
        &::after{
            content: '';
            display: block;
            width: 6px;
            height: 10px;
            background: url(/asset/images/icn-arrow.png) no-repeat center/100%;
            position: absolute;
            right: -10px;
            top: 4px;
            transition: right .2s ease-in;
        }
    }
    &:hover{
        span{
            &::after{
                right: -13px;
            }
        }
    }
}
.btn_register{
    .inline-block;
    min-width: 168px;
    padding: 7px 26px 9px;
    background-color: @pointColor;
    span{
        font-weight: 500;
        letter-spacing: -.67px;
        color: white;
        &::before{
            content: '';
            .inline-block;
            width: 17px;
            height: 17px;
            background: url(/asset/images/icn-write.png) no-repeat center/100%;
            margin-right: 10px;
            vertical-align: -3px;
        }
    }
}
.btn_rg_request{
    .inline-block;
    padding: 6px 26px 8px;
    background-color: #fff;
    border: @border-pointColor;
    text-align: center;
    span{
        font-size: 16px;
        font-weight: 500;
        letter-spacing: -.67px;
        color: @pointColor;
    }
}
.btn01{
    .inline-block;
    padding: 15px 0;
    width: 270px;
    background-color: @pointColor;
    text-align: center;
    cursor: pointer;
    span{
        font-size: 17px;
        font-weight: 500;
        letter-spacing: 1px;
        color: white;
    }
}
.btn02{
    width: 138px;
    padding: 10px 0;
    text-align: center;
    background-color: @pointColor;
    display: inline-block;
    cursor: pointer;
    span{
        display: block;
        font-size: 14px;
        font-weight: 500;
        color: #fff;
    }
}
.btn_file_add{
    .inline-block;
    padding: 5px 12px 6px;
    background-color: white;
    border: @border-pointColor;
    cursor: pointer;
    span{
        font-size: 13px;
        font-weight: 500;
        letter-spacing: -.54px;
        color: @pointColor;
        &::before{
            content: '';
            .inline-block;
            width: 17px;
            height: 17px;
            background: url(/asset/images/icn-write-navy.png) no-repeat center/100%;
            margin-right: 5px;
            vertical-align: -4px;
        }
    }
}
.btn_download{
    .inline-block;
    padding: 8px 18px;
    background-color: @pointColor;
    span{
        font-weight: 500;
        letter-spacing: -.67px;
        color: white;
        &::before{
            content: '';
            .inline-block;
            width: 14px;
            height: 14px;
            background: url(/asset/images/icn-attachedfile-white.png) no-repeat center/11px;
            vertical-align: -1px;
            margin-right: 10px;
        }
    }
}
.btn_file_download{
    .inline-block;
    max-width: 100%;
    border-radius: 24px;
    background-color: @bgColor01;
    padding: 12px 24px;
    span{
        display: inline-block;
        max-width: 100%;
        font-size: 14px;
        font-weight: 300;
        .line-clamp-one;
        &::before{
            content: '';
            .inline-block;
            width: 13px;
            height: 13px;
            background: url(/asset/images/icn-attachedfile.png) no-repeat center/11px;
            margin-right: 10px;
            vertical-align: -2px;
        }
    }
    &:hover{
        span{
            text-decoration: underline;
        }
    }
}
.btn_top{
    position: fixed;
    right: 40px;
    bottom: 0;
    z-index: 10;
    padding-bottom: 40px;
    span{
        display: block;
        width: 40px;
        height: 40px;
        border: 1px solid @pointColor;
        background: white url(/asset/images/icn-w-top.png) no-repeat center/10px;
        transform: scale(0);
        transition: transform .2s ease-in;
        cursor: pointer;
    }
    &.show{
        span{
            transform: scale(1);
        }
    }
    &.sticky{
        position: absolute;
        bottom: auto;
        top: -(40px+40px);
    }
}

/*card_box*/
.card_box01{
    a{
        display: block;
        .afters;
        .img_box{
            height: 200px;
            overflow: hidden;
            .img{
                width: 100%;
                height: 100%;
                .bgo;
                background-color: lightgrey;
                transition: all .3s ease-in-out;
            }
        }
        figcaption{
            .group{
                font-size: 14px;
                font-weight: 500;
                color: @pointColor;
                margin-bottom: 6px;
                text-indent: 2px;
                .line-clamp-one;
            }
            .title,.subtitle{
                font-weight: 300;
                line-height: 1;
            }
            .subtitle{
                .line-clamp-one;
            }
            .desc{
                font-size: 14px;
                font-weight: 500;
                margin-bottom: 20px;
            }
            
        }
        .left,.right{
            float: left;
        }
        &.ver1{
            .img_box{
                height: 440px;
            }
            figcaption{
                padding-top: 20px;
                .title,.subtitle{
                    // font-size: 42px;
                    font-size: 34px;
                    white-space: nowrap;
                }
                .title{
                    .line-clamp-one;
                    margin-bottom: 15px;
                }
                .subtitle{
                    font-size: 24px;
                    margin-bottom: 20px;
                }
                .desc{
                    .line-clamp(1.71;2);
                }
            }
        }
        &.ver2{
            .left{
                width: 44.7%;
                &.img_box{
                    height: 200px;
                }
            }
            .right{
                width: 55.3%;
                padding-left: 5%;
            }
            figcaption{
                .title,.subtitle{
                    letter-spacing: -.5px;
                }
                .title{
                    .line-clamp(1.17;2);
                    font-size: 24px;
                    margin-bottom: 8px;
                }
                .subtitle{
                    font-size: 16px;
                    margin-bottom: 10px;
                }
                .desc{
                    .line-clamp(1.67;2);
                    font-size: 12px;
                }
            }
        }
        &.ver3{
            .left{
                width: 48.7%;
                &.img_box{
                    height: 422px;
                }
            }
            .right{
                width: 51.3%;
                padding-left: 4%;
            }
            figcaption{
                .title,.subtitle{
                    letter-spacing: -.46px;
                }
                .title{
                    .line-clamp(1.17;2);
                    font-size: 34px;
                    margin-bottom: 10px;
                }
                .subtitle{
                    font-size: 22px;
                    margin-bottom: 35px;
                }
                .desc{
                    .line-clamp(1.71;6);
                }
            }
        }
    }
    &:hover{
        .img_box{
            .img{
                transform: scale(1.1) translateZ(1px);
            }
        }
        a{
            .btn_read_more{
                .btn_read_more_hover;
            }
        }
    }
}
.card_box02{
    a{
        display: block;
        position: relative;
        .img_box{
            position: relative;
            overflow: hidden;
            .img{
                .bgo;
                background-color: lightgrey;
                padding-top: 140%;
                transition: all .3s ease-in-out;
            }
            .badge{
                display: block;
                min-width: 78px;
                text-align: center;
                color: #fff;
                font-weight: 500;
                padding: 3px 4px 5px;
                position: absolute;
                left: 0;
                top: 0;
            }
            .on_going_badge{
                width: 90px;
                height: 90px;
                position: absolute;
                right: 0;
                bottom: 0;
                &::before{
                    content: '';
                    width: 0px;
                    height: 0px;
                    border-top: 90px solid transparent;
                    border-right: 90px solid @pointColor;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                }
                span{
                    .eFont;
                    font-size: 19px;
                    line-height: .84;
                    color: #fff;
                    text-align: right;
                    position: absolute;
                    right: 10px;
                    bottom: 10px;
                }
            }
        }
        figcaption{
            padding-top: 14px;
            .title{
                font-size: 26px;
                font-weight: 300;
                .line-clamp(1.1;2);
                margin-bottom: 5px;
            }
            >ul{
                >li{
                    float: none;
                    display: inline;
                    word-break: keep-all;
                    font-weight: 500;
                    line-height: 1.56;
                    color: @textColor01;
                    margin-right: 10px;
                    &::after{
                        content: '';
                        .inline-block;
                        width: 1px;
                        height: 10px;
                        background-color: @grey01;
                        margin-left: 10px;
                        vertical-align: 1px;
                    }
                    &:last-child{
                        &::after{
                            .bld;
                        }
                    }    
                }
            }
            .location_box{
                .city{
                    &::before{
                        content: '';
                        .inline-block;
                        width: 14px;
                        height: 18px;
                        .bgo;
                        background-image: url(/asset/images/icn-ongoing-pin.png);
                        margin-right: 5px;
                        vertical-align: -3px;
                    }
                }
            }
        }
        &:hover{
            .img_box{
                .img{
                    transform: scale(1.1)  translateZ(1px);
                }
            }
        }
    }
}
.card_box03{
    a{
        display: block;
        .afters;
        .left,.right{
            float: left;
        }
        .left{
            width: 47%;
        }
        .right{
            width: 53%;
            padding-left: 5%;
        }
        .img_box{
            overflow: hidden;
            .img{
                .bgo;
                background-color: lightgrey;
                padding-top: 96.6%;
                transition: all .3s ease-in-out;
            }
        }
        figcaption{
            .title,.subtitle{
                font-weight: 300;
                line-height: 1.33;
                letter-spacing: -.5px;
            }
            .title{
                font-size: 24px;
                margin-bottom: 10px;
                .line-clamp(1.33;4);
            }
            .subtitle{
                .line-clamp-one;
            }
        }
        &:hover{
            .img_box{
                .img{
                    transform: scale(1.1)  translateZ(1px);
                }
            }
        }

    }
}
.card_box04{
    a{
        display: block;
        .img_box{
            background-color: lightgrey;
            transition: box-shadow .2s ease-in;
            .img{
                padding-top: 134.6%;
                .bgo;
            }
        }
        figcaption{
            padding-top: 20px;
            text-align: center;
            .title{
                .eFont;
                font-size: 28px;
                line-height: 1.14;
                margin-bottom: 5px;
            }
            .subtitle{
                font-size: 20px;
                font-weight: 300;
                letter-spacing: -.42px;
            }
        }
        &:hover{
            .img_box{
                box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.3);
            }
        }
    }
}
.card_box05{
    a{
        display: block;
        .img_box{
            overflow: hidden;
            .img{
                padding-top: 96.4%;
                .bgo;
                background-color: lightgrey;
                transition: all .3s ease-in-out;
            }
        }
        figcaption{
            padding-top: 20px;
            .title{
                font-size: 26px;
                font-weight: 300;
                margin-bottom: 5px;
                .line-clamp(1.23;2)
            }
            .subtitle{
                font-weight: 500;
                color: @textColor01;
                .line-clamp-one;
            }

        }
        &:hover{
            .img_box{
                .img{
                    transform: scale(1.1)  translateZ(1px);
                }
            }
        }
    }
}
.card_box06{
    a{
        display: block;
        .img_box{
            overflow: hidden;
            .img{
                .bgo;
                background-color: lightgrey;
                padding-top: 96.4%;
                transition: all .3s ease-in-out;
            }
        }
        figcaption{
            padding-top: 20px;
            .title{
                font-size: 26px;
                font-weight: 300;
                .line-clamp(1.23;2);
                margin-bottom: 10px;
            }
            .subtitle{
                font-weight: 500;
                color: #595959;
                .line-clamp-one;
            }
        }
        &:hover{
            .img_box{
                .img{
                    transform: scale(1.1) translateZ(1px)
                }
            }
        }
    }
}
/*boxes*/
.box01{
    display: block;
    border: @border01;
    background-color: white;
    padding: 30px 2.5% 25px;
    position: relative;
    &::before{
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        border: 2px solid @pointColor;
        position: absolute;
        left: -1px;
        top: -1px;
        opacity: 0;
        transition: opacity .2s ease-in;
    }
    .badge_box{
        margin-bottom: 10px;
        .badge{
            .inline-block;
            padding: 0 6px 1px;
            font-size: 13px;
            font-weight: 500;
            letter-spacing: 0.09px;
            color: white;
            margin-right: 5px;
            &:last-child{
                margin-right: 0;
            }
        }
    }
    .title{
        font-size: 24px;
        font-weight: 300;
        letter-spacing: -.5px;
        margin-bottom: 15px;
        .line-clamp(1.33;2);
    }
    .date{
        font-weight: 300;
    }
    &.impt{
        background-color: @bgColor01;
    }
    &:hover{
        &::before{
            opacity: 1;
        }
    }
}
.box02{
    display: inline-block;
    border: 1px solid @grey01;
    background-color: @bgColor01;
    padding: 20px 0;
    text-align: center;
    span{
        font-size: 16px;
        font-weight: bold;
        line-height: 1;
        letter-spacing: -.5px;
    }
    &:hover{
        background-color: @pointColor;
        border: 1px solid @pointColor;
        span{
            color: white;
        }
    }
}
.list_box01{
    display: table;
    table-layout: fixed;
    width: 100%;
    border-bottom: @border01;
    padding: 17px 0;
    .img_box,.info_box{
        display: table-cell;
        vertical-align: middle;
    }
    .img_box{
        width: 48px + 30px;
        padding: 0 15px;
        .img{
            width: 100%;
            padding-top: 133.3%;
            .bgo;
            background-color: lightgrey;
        }
    }
    .info_box{
        width: calc(100% - (48px + 30px));
        .row{
            margin-bottom: 5px;
            width: 100%;
            overflow: hidden;
            &:last-child{
                margin-bottom: 0;
            }
            .afters;
            .badge_box,.title{
                float: left;
            }
            .badge_box{
                margin-top: 4px;
                margin-right: 10px;
                .badge{
                    display: inline-block;
                    min-width: 50px;
                    padding: 0 1px 1px;
                    font-size: 13px;
                    font-weight: 500;
                    letter-spacing: 0.09px;
                    color: white;
                    text-align: center;
                    vertical-align: middle;
                    &.ongoing{
                        padding: 3px 1px;
                        letter-spacing: normal;
                        font-size: 11px;
                    }
                }
            }
            .title{
                width: calc(100% - 150px);
                font-size: 24px;
                font-weight: 300;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden
            }
            >ul{
                .inline-block;
                margin-right: 30px;
                &:last-of-type{
                    margin-right: 0;
                }
                li{
                    font-weight: 500;
                    color: @textColor02;
                    margin-right: 10px;
                    &::after{
                        content: '';
                        .inline-block;
                        width: 1px;
                        height: 10px;
                        background-color: @grey01;
                        margin-left: 10px;
                        vertical-align: 1px;
                    }
                    &:last-child{
                        margin-right: 0;
                        &::after{
                            .bld;
                        }
                    }
                    &.city{
                        &::before{
                            content: '';
                            .inline-block;
                            width: 14px;
                            height: 18px;
                            .bgo;
                            background-image: url(/asset/images/icn-ongoing-pin.png);
                            margin-right: 5px;
                            vertical-align: -3px;
                        }
                    }
                }
            }
        }
    }
    &:hover{
        background-color: @bgColor01;
    }
}
.list_box02{
    display: table;
    width: 100%;
    table-layout: fixed;
    padding: 15px 0;
    border-bottom: @border01;
    .col{
        display: table-cell;
        vertical-align: middle;
    }
    .img_box{
        width: 140px;
        padding-left: 30px;
        padding-right: 20px;
        .img{
            width: 88px;
            padding-top: 100%;
            .bgo;
            background-color: lightgrey;
        }
    }
    .info_box{
        width: calc(100% - 140px - 140px);
        .kind{
            font-size: 15px;
            font-weight: 500;
            letter-spacing: .11px;
            color: @pointColor;
            margin-bottom: 3px;
        }
        .title{
            font-size: 26px;
            font-weight: 500;
            letter-spacing: -.54px;
            .line-clamp-one;
            margin-bottom: 5px;
        }
        ul{
            li{
                font-size: 18px;
                font-weight: 300;
                letter-spacing: -.56px;
            }
        }
    }
    .date_box{
        width: 140px;
        padding-right: 30px;
        padding-left: 20px;
        .date{
            font-weight: 300;
        }
    }
    &:hover{
        background-color: @bgColor01;
    }
}
.list_box03{
    .afters;
    .left,.right{
        float: left;
    }
    .left{
        width: 240px;
        .title{
            .eFont;
            font-size: 20px;
        }
    }
    .right{
        width: calc(100% - 240px);
        >p{
            letter-spacing: -.5px;
        }
        .img_box{
            margin-bottom: 10px;
            img{
                display: block;
            }
        }
        .color_box{
            width: 208px;
            border: 1px solid @pointColor;
            .title{
                padding: 3px 20px 5px;
                background-color: @pointColor;
                p{
                    .eFont;
                    font-size: 20px;
                    color: #fff;
                }
            }
            .cont{
                padding: 16px 20px 17px;
                background-color: #fff;
                p{
                    font-size: 13px;
                    font-weight: 500;
                    color: @pointColor;
                    line-height: 1.69;
                }
            }
        }
    }
}
.img_sld_box{
    height: 152px;
    display: block;
    img{
        width: auto;
        height: 100%;
    }
}

/*tabs*/
.tab01{
    text-align: center;
    .inner{
        .inline-block;
        border-bottom: 1px solid @grey02;
        >ul,.btn_read_more{
            vertical-align: middle
        }
        ul{
            .inline-block;
            li{
                width: 186px;
                a{
                    display: block;
                    font-size: 18px;
                    font-weight: 500;
                    color: @textColor03;
                    padding: 10px 0;
                    -webkit-transform: perspective(1px) translateZ(0);
                    transform: perspective(1px) translateZ(0);
                    box-shadow: 0 0 1px transparent;
                    position: relative;
                    &::before{
                        content: "";
                        position: absolute;
                        z-index: 1;
                        left: 50%;
                        right: 50%;
                        bottom: -1px;
                        background: @pointColor;
                        height: 2px;
                        transition-property: left, right;
                        transition-duration: 0.2s;
                        transition-timing-function: ease-out;
                    }
                    &.on{
                        color: @pointColor !important;
                        &::before{
                            left: 0;
                            right: 0;
                        }
                    }
                    &:hover{
                        .on;
                    }
                }
            }
        }
        .btn_read_more{
            margin-left: 10px;
            .inner{
                span{
                    &::after{
                        margin-left: 20px;
                    }
                }
            }
            &:hover{
                .btn_read_more_hover;
            }
        }
    }
}
.tab02{
    text-align: right;
    .inner{
        .inline-block;
        >ul,.btn_map{
            .inline-block;
            vertical-align: middle;
        }
        >ul{
            margin-right: 28px;
            li{
                margin-right: 8px;
                &::after{
                    content: '';
                    .inline-block;
                    width: 1px;
                    height: 8px;
                    background-color: @grey03;
                    margin-left: 8px;
                    vertical-align: 3px;
                }
                &:last-child{
                    margin-right: 0;
                    &::after{
                        .bld;
                    }
                }
                a{
                    font-size: 13px;
                    color: @grey01;
                    font-weight: bold;
                    &::before{
                        content: '';
                        .inline-block;
                        width: 16px;
                        height: 16px;
                        margin-right: 4px;
                        vertical-align: -3px;
                        .bgo;
                    }
                    &.list{
                        &::before{
                            background-image: url(/asset/images/icn-ongoing-list-dis.png);
                            background-size: 14px;
                        }
                    }
                    &.calender{
                        &::before{
                            background-image: url(/asset/images/icn-calandar-dis.png);
                            background-size: 14px;
                        }
                    }
                    &.on{
                        color: @pointColor;
                        &.list{
                            &::before{
                                background-image: url(/asset/images/icn-ongoing-list-act.png);
                            }
                        }
                        &.calender{
                            &::before{
                                background-image: url(/asset/images/icn-calandar-act.png);
                            }
                        }
                    }
                    &:hover{
                        .on;
                    }
                }
            }
        }
    }
}
/*panels*/
.panel01{
    .panel_tit{
        padding-bottom: 16px;
        text-align: center;
        p{
            .eFont;
            font-size: 60px;
            letter-spacing: 2.31px;
            color: @pointColor;
        }
    }
}
.panel02{
    .panel_tit{
        font-size: 24px;
        font-weight: bold;
        color: @pointColor;
        margin-bottom: 15px;
    }
    .panel_cont{
        p{
            font-weight: 500;
            line-height: 1.63;
            span{
                font-weight: inherit;
                line-height: inherit;
                font-size: inherit;
                &.strong{
                    font-weight: bold;
                }
                &.point{
                    font-weight: bold;
                    color: @pointColor;
                    &.eFont{
                        font-size: 22px;
                    }
                }
            }
        }
    }
}
.panel03{
    border: @border02;
    padding: 30px 0 40px;
    text-align: center;
    .panel_tit{
        .title{
            font-size: 22px;
            font-weight: bold;
            color: @pointColor;
            .num{
                font-size: 28px;
                .eFont;
                color: inherit;
            }
        }
        .subtitle{
            font-weight: 500;
            line-height: 1.63;
            margin-top: 5px;
        }
        .cmt{
            font-size: 12px;
            font-weight: 500;
            margin-top: 5px;
            color: @textColor01;
        }
    }
    .panel_cont{
        padding-top: 40px;
    }
}
.panel04{
    .panel_tit{
        padding-bottom: 20px;
        text-align: center;
        .title{
            font-size: 26px;
            font-weight: bold;
            color: @pointColor;
        }
    }
    .panel_cont{
        border-top: @border-pointColor;
        border-bottom: @border-pointColor;
    }
}
.panel05{
    display: flex;
    .panel_tit{
        flex: 1;
        font-size: 20px;
        font-weight: bold;     
    }
    .panel_cont{
        flex : 5;
    }
}
/*tables*/
.table01{
    display: table;
    table-layout: fixed;
    width: 100%;
    border-top: @border-pointColor;
    border-bottom: @border-pointColor;
    .tr{
        display: table-row;
        .th,.td{
            height: 62px;
            display: table-cell;
            vertical-align: middle;
            background-color: #fff;
            text-align: center;
        }
        .th{
            font-size: 15px;
            font-weight: 500;
            letter-spacing: 2.34px;
        }
        .td{
            border-top: 1px solid @grey02;
            font-weight: 300;
            .badge_box{
                .badge{
                    display: inline-block;
                    padding: 1px 8px 2px;
                    font-size: 12px;
                    color: white;
                    min-width: 42px;
                    text-align: center;
                }
            }
            &:nth-child(2){
                text-align: left;
                .inner{
                    display: table;
                    >div{
                        display: table-cell;
                        vertical-align: middle;
                    }
                }
                .badge_box{
                    padding-right: 10px;
                }
                .title{
                    font-weight: inherit;
                    letter-spacing: -.71;
                    p{
                        padding-right: 18px;
                        position: relative;
                        .line-clamp(1.53; 2);
                    }
                }
            }
            &.new{
                &:nth-child(2){
                    .title{
                        p{
                            &::after{
                                content: '';
                                .inline-block;
                                width: 13px;
                                height: 13px;
                                background: url(/asset/images/icn-new.png) no-repeat center/100%;
                                position: absolute;
                                right: 0;
                                top: 50%;
                                transform: translateY(-50%);
                            }
                        }
                    }
                }
            }
        }
        &.impt{
            .td{
                background-color: @bgColor01;
                .title{
                    p{
                        font-size: 17px;
                        font-weight: 500;
                    }
                }
                &:nth-child(2){
                    .badge_box{
                        .important{
                            .bld;
                        }
                    }
                }    
            }
        }
        &:hover{
            .td{
                .title{
                    p{
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}
.table02{
    display: table;
    table-layout: fixed;
    width: 100%;
    border-top: @border-pointColor;
    border-bottom: @border-pointColor;
    .tr{
        display: table-row;
        .th,.td{
            // height: 62px;
            display: table-cell;
            vertical-align: middle;
            background-color: #fff;
            text-align: center;
        }
        .th{
            padding: 20px 0;
            font-size: 15px;
            font-weight: 500;
            letter-spacing: 2.34px;
            >span{
                font-size: inherit;
                font-weight: inherit;
                letter-spacing: inherit;
                margin-right: 10px;
                &:last-child{
                    margin-right: 0;
                }
            }
        }
        .td{
            padding: 18px 0;
            border-top: 1px solid @grey02;
            font-weight: 300;
            .badge_box{
                .badge{
                    display: inline-block;
                    padding: 1px 0 2px;
                    font-size: 12px;
                    color: white;
                    min-width: 50px;
                    text-align: center;
                }
            }
            &:nth-child(3){
                p{
                    font-weight: inherit;
                    text-align: left;
                    .line-clamp(1.53; 2);
                }
            }
            &:last-child{
                position: relative;
            }
            .edit_buttons{
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 90px;
                // .inline-block;
                li{
                    margin-right: 10px;
                    &:last-child{
                        margin-right: 0;
                    }
                    button{
                        font-size: 0;
                        width: 40px;
                        height: 40px;
                        .bgo;
                        background-color: @pointColor;
                        cursor: pointer;
                        &.btn_edit{
                            background-image: url(/asset/images/icn-pencil.png);
                            background-size: 19px;
                        }
                        &.btn_delete{
                            background-image: url(/asset/images/icn-del.png);
                            background-size: 13px;
                        }
                    }
                }
            }
            
        }
        &:hover{
            .td{
                &:nth-child(3){
                    p{
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}
.table03{
    table-layout: fixed;
    width: 100%;
    border-top: 1px solid @pointColor;
    th,td{
        padding: 18px 5px;
        border-bottom:  1px solid @grey01;
        font-size: 17px;
        text-align: center;
    }
    th{
        background-color: @bgColor01;
        font-weight: 500;
        letter-spacing: -.71px;
    }
    td{
        font-weight: 300;
        letter-spacing: -.71px;
        word-break: keep-all;
        a{
            font-weight: inherit;
            letter-spacing: inherit;
            font-size: inherit;
            text-decoration: underline;
            word-break: break-all;
        }
        &.tal{
            text-align: left;
        }
    }
    &.tdrow{
        td{
            padding: 18px 38px; 
        }
    }
}

/*lists*/
.list01{
    li{
        float: none;
        font-size: 13px;
        font-weight: 300;
        margin-bottom: 10px;
        position: relative;
        padding-left: 5px;
        word-break: keep-all;
        &:last-child{
            margin-bottom: 0;
        }
        &::before{
            content: '';
            display: block;
            width: 2px;
            height: 2px;
            background-color: @grey02;
            position: absolute;
            left: 0;
            top: 8px;
        }
    }
}
.list02{
    li{
        float: none;
        font-size: 15px;
        font-weight: 300;
        margin-bottom: 10px;
        position: relative;
        padding-left: 10px;
        word-break: keep-all;
        &:last-child{
            margin-bottom: 0;
        }
        &::before{
            content: '';
            display: block;
            width: 2px;
            height: 2px;
            background-color: @grey02;
            position: absolute;
            left: 0;
            top: 11px;
        }
    }
}

/*pagination*/
.paging_area{
    text-align: center;
    .pagination{
        .inline-block;
        >div{
            .inline-block;
            vertical-align: middle;
        }
        .prev,.next{
            .navigationBtnCommon
        }
        .prev{
            .navigationPrev;
        }
        .next{
            .navigationNext;
        }
        .num{
            margin: 0 46px;
            a{
                .inline-block;
                width: 14px;
                height: 14px;
                border: 1px solid white;
                border-radius: 50%;
                background-color: white;
                position: relative;
                margin-right: 6px;
                &:last-child{
                    margin-right: 0;
                }
                &:after{
                    content: '';
                    display: block;
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    background-color: @pointColor;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%,-50%);
                }
                &.on{
                    border: @border-pointColor;
                }
            }
        }
    }
    .pagination02{
        .inline-block;
        >div{
            .inline-block;
            vertical-align: middle;
        }
        .prev,.next,.prev_first,.next_last{
            .navigationBtnCommon
        }
        .prev{
            .navigationPrev;
        }
        .prev_first{
           .navigationPrevFirst;
            margin-right: 8px;
        }
        .next{
            .navigationNext;
            margin-right: 8px;
        }
        .next_last{
           .navigationNextLast;
        }
        .num{
            margin: 0 30px;
            a{
                .inline-block;
                margin-right: 15px;
                &:last-child{
                    margin-right: 0;
                }
                span{
                    .eFont;
                    font-size: 19px;
                    color: @grey02;
                }
                &.on{
                    span{
                        color: @pointColor;
                    }
                }
            }
        }
    }
}
/*header*/
header{
    background-color: #fff;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 10;
    .wrap01{
        position: relative;
    }
    .btn_ham{
        display: block;
        width: 22px;
        height: 16px;
        position: absolute;
        right: @padding + 10px;
        top: 22px;
        z-index: 20;
        cursor: pointer;
        &::before{
            content: '';
            display: block;
            width: 44px;
            height: 44px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            opacity: 0;
            transition: opacity .2s ease-in;
        }
        span{
            display: block;
            width: 100%;
            height: 2px;
            background-color: @pointColor;
            position: absolute;
            left: 0;
            transition: top .2s ease-in, transform .2s ease-in, background-color .2s ease-in;
            &:nth-child(1){
                top: 0;
            }
            &:nth-child(2){
                top: 45%;
            }
            &:nth-child(3){
                top: calc(100% - 2px);
            }
        }
        &.close{
            span{
                &:first-child {
                    top: 50%;
                    transform: rotate(45deg);
                }
                &:nth-child(2) {
                    width: 0;
                    // left: 20px;
                }

                &:last-child {
                    top: 50%;
                    transform: rotate(-45deg);
                }
            }
        }
        &.close_ver1{
            .close;
            // &::before{
            //     background-color: @grey03;
            //     opacity: 1;
            // }
            span{
                background-color: #fff;
            }
        }
    }
    .btn_friends_close{
        visibility: hidden;
        width: 44px;
        height: 44px;
        position: absolute;
        right: @padding;
        top: 8px;
        z-index: 20;
        cursor: pointer;
        background-color: @pointColor;
        padding: 14px 11px;
        .inner{
            width: 22px;
            height: 16px;
            position: relative;
            span{
                display: block;
                width: 100%;
                height: 2px;
                background-color: white;
                position: absolute;
                left: 0;
                transition: top .3s ease-in, transform .3s ease-in;
                &:nth-child(1){
                    top: 0;
                }
                &:nth-child(2){
                    top: 45%;
                }
                &:nth-child(3){
                    top: calc(100% - 2px);
                }
            }
        }
       &.show{
            visibility: visible;
            span{
                &:first-child {
                    top: 50%;
                    transform: rotate(45deg);
                }
                &:nth-child(2) {
                    width: 0;
                    // left: 20px;
                }

                &:last-child {
                    top: 50%;
                    transform: rotate(-45deg);
                }
            }
       }
    }
    .top_gnb_area{
        .afters;
        padding: 16px 0 8px;
        >div{
            float: right;
            >ul{
                display: inline-block;
                margin-right: 36px + 22px;
                li{
                    margin-right: 18px;
                    &:last-child{
                        margin-right: 0;
                    }
                    a{
                        font-size: 12px;
                        font-weight: bold;
                        letter-spacing: -.33px;
                        color: @pointColor;
                    }
                    &.lang{
                        >a{
                            color: @grey01;
                            &::after{
                                content: ' I ';
                                color: @grey01;
                            }
                            &:last-child{
                                &::after{
                                    .bld;
                                }
                            }
                            &.on{
                                color: @pointColor;
                            }
                        }
                    }
                    &.login_after{
                        .bld !important;
                    }
                }
            }
        }
        &.login{
            >div{
                >ul{
                    li{
                        &.login_before{
                            .bld !important;
                        }
                        &.login_after{
                            display: block !important;
                        }
                    }
                }
            }
        }
    }
    .main_gnb_area{
        font-size: 0;
        padding-bottom: 24px - 5px;
        nav{
            display: inline-block;
            vertical-align: text-bottom;
            ul{
                li{
                    float: none;
                    display: inline-block;
                    font-size: 0;
                    vertical-align: text-bottom;
                    // margin-right: 20px;
                    margin-right: 10px;
                    margin-bottom: 5px;
                    &:last-child{
                        margin-right: 0;
                    }
                    &:first-child{
                        margin-right: 10px;
                    }
                    a{
                        display: block;
                        // font-size: 40px;
                        font-size: 43px;
                        font-weight: 500;
                        letter-spacing: -.8px;
                        color: @pointColor;
                        text-transform: capitalize;
                        &.on{
                            color: #fff;
                            background-color: @pointColor;
                        }
                        &:hover{
                            .on;
                        }
                    }
                    .logo{
                        a{
                            display: block;
                            // width: 120px;
                            // height: 132px;
                            width: 140px;
                            height: 152px;
                            img{
                                display: block;
                                height: 100%;
                            }
                            &:hover{
                                background-color: transparent;
                            }
                        }
                    }
                }
            }
        }
    }
    .search_area{
        border-top: @border-pointColor;
        border-bottom: @border-pointColor;
        padding: 6px 0;
        .inner{
            position: relative;
            padding: 0 6px;
            .ggc{
                width: 0;
                background-color: @pointColor;
                position: absolute;
                top: 0;
                left: 0;
                transition: width .3s ease-in;
                transition-delay: .2s;
                overflow: hidden;
                span{
                    position: relative;
                    top: -5px;
                    font-size: 40px;
                    .eFont;
                    color: white;
                    line-height: 1;
                    letter-spacing: 4px;
                }
            }
            form{
                .afters;
                input[type="text"],.btn_search{
                    float: left;
                }
                input[type="text"]{
                    width: calc(100% - 40px);
                    height: 40px;
                    text-align: right;
                    padding: 0 20px 0 (106px+10px);
                    font-size: 24px;
                    font-weight: 500;
                    color: @pointColor;
                    &:focus{
                        outline: none;
                    }
                }
                .btn_search{
                    padding: 0;
                    margin: 0;
                    cursor: pointer;
                    span{
                        width: 40px;
                        height: 40px;
                        display: block;
                        background: @pointColor url(/asset/images/icn-zoom.png) no-repeat center/24px;
                    }
                }
            }
        }
        &.sticky{
            .inner{
                .ggc{
                    width: 106px;
                }
            }
        }
    }
    .friends{
        .bld;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 336px;
        background-color: white;
        z-index: 10;
        outline: @border-pointColor;
        padding-top: 44px;
        .check_option_box{
            text-align: right;
            padding: 5px 0;
        }
        .friends_list_box{
            border-top: 1px solid @grey02;
            padding-top: 20px;
            .keyword{
                .eFont;
                font-size: 40px;
                color: @pointColor;
            }
            >a{
                display: inline-block;
               font-size: 18px;
               font-weight: bold;
               color: @grey01;
               margin-right: 10px;
               word-break: keep-all;
               &:hover{
                   color: @pointColor;
               }
            }
        }

    }
    .site_map{
        .bld;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        // height: 520px;
        height: 600px;
        background-color: white;
        z-index: 10;
        .afters;
        .wrap01{
            height: 100%;
            >div{
                float: left;
                height: 100%;
                padding: 70px 3%;
            }
            .group1{
                width: 34%;
                background-color: #093766;
                a{
                    .inline-block;
                    margin-right: 13px;
                    margin-bottom: 35px;
                    // .hover-vertical-line(2px);
                    &:hover{
                        background-color: #fff;
                        span{
                            color: @pointColor;
                        }
                    }
                    &:last-child{
                        margin-right: 0;
                    }
                    span{
                        font-size: 40px;
                        font-weight: 300;
                        letter-spacing: .8px;
                        line-height: 1;
                        color: white;
                        text-transform: capitalize;
                    }
                    &.impt{
                        span{
                            font-size: 42px;
                            // font-weight: bold;
                        }
                    }
                    
                }
            }
            .group2{
                width: 40.5%;
                padding: 70px 3.5%;
                background-color: #003c71;
                ul{
                    width: 100%;
                    li{
                        width: (100% - 9.8%)/2;
                        margin-bottom: 30px;
                        height: 66px;
                        &:nth-child(odd){
                            margin-right: 9.8% 
                        }
                        a{
                            .inline-block;
                            text-align: left;
                            .title{
                                .inline-block;
                                .eFont;
                                font-size: 32px;
                                letter-spacing: 1.6px;
                                color: white;
                                margin-bottom: 5px;
                                position: relative;
                                // &::after{
                                //     content: '';
                                //     display: block;
                                //     width: 0;
                                //     height: 2px;
                                //     background-color: #fff;
                                //     position: absolute;
                                //     left: 0;
                                //     top: 55%;
                                //     transition: width .2s ease-in;
                                // }
                                &:hover{
                                    background-color: #fff;
                                    color: @pointColor;
                                }
                            }
                            .subtitle{
                                font-size: 13px;
                                font-weight: 500;
                                color: #6c95c0;
                            }
                            // &.on{
                            //     .title{
                            //         &::after{
                            //             width: 100%;
                            //         }
                            //     }
                                
                            // }
                            // &:hover{
                            //     .on;
                            // }
                        }
                    }
                }
            }
            .group3{
                width: 25.5%;
                background-color: #0e4278;
                dl{
                    float: none;
                    margin-bottom: 24px;
                    &:last-of-type{
                        margin-bottom: 0;
                    }
                    dt{
                        margin-bottom: 10px;
                        a{
                            font-size: 40px;
                            font-weight: 300;
                            letter-spacing: 2px;
                            color: white;
                            line-height: 1;
                            position: relative;
                            // .hover-vertical-line(2px);
                            &:hover{
                                background-color: #fff;
                                color: @pointColor;
                            }
                        }
                    }
                    dd{
                        ul{
                            width: 100%;
                            li{
                                margin-right: 5px;
                                &::after{
                                    content: '';
                                    .inline-block;
                                    width: 2px;
                                    height: 2px;
                                    border-radius: 50%;
                                    background-color: #fff;
                                    margin-left: 5px;
                                    vertical-align: 5px;
                                }
                                &:last-child{
                                    margin-right: 0;
                                    &::after{
                                        .bld;
                                    }
                                }
                                a{
                                    width: 14px;
                                    font-weight: 500;
                                    color: white;
                                    letter-spacing: -.39px;
                                    position: relative;
                                    // .hover-vertical-line(2px);
                                }
                            }
                        }  
                    }
                }
            }
        }
    }
    &.sticky{
        position: fixed;
        .main_gnb_area{
            .bld;
        }
        .search_area{
            .search_area.sticky;
        }
    }
}
/*footer*/
footer{
    border-top: @border01;
    padding: 40px 0;
    position: relative;
    .left{
        float: left;
    }
    .right{
        float: right;
    }
    .g1{
        &.left{
            width: 11.7%;
            .ft_logo{
                a{
                    display: block;
                    margin-bottom: 10px;
                    width: 100px;
                    &:nth-child(1){
                        height: 43px;
                    }
                    &:nth-child(2){
                        height: 60px;
                        pointer-events: none;
                    }
                    img{
                        display: block;
                        margin: 0 auto;
                        height: 100%;
                    }
                }
                
            }
        }
        &.right{
            width: 88.3%;
        }
    }
    .g2{
        &.left{
            width: calc(100% - 210px);
            text-align: center;
            // .company{
            //     font-size: 15px;
            //     font-weight: bold;
            //     color: @pointColor;
            //     margin-bottom: 5px;
            //     a{
            //         display: inline-block;
            //         border-bottom: 1px solid #a8a8a8;
            //         margin-left: 12px;
            //         font-size: 0;
            //         vertical-align: 3px;
            //         span{
            //             font-size: 9px;
            //             letter-spacing: -.54px;
            //             color: #a8a8a8;
            //             position: relative;
            //             padding-right: 8px;
            //             &::after{
            //                 content: '';
            //                 display: block;
            //                 width: 4px;
            //                 height: 4px;
            //                 background: url(/asset/images/icn-btn-outlink-gray.png) no-repeat center/100%;
            //                 position: absolute;
            //                 right: 0;
            //                 top: 5px;
            //                 transition: right .2s ease-in, top .2s ease-in;
            //             }
            //         }
            //         &:hover{
            //             span{
            //                 &::after{
            //                     right: -2px;
            //                     top: 3px;
            //                 }
            //             }
            //         }
            //     }
            // }
            // address{
            //     font-style: normal;
            //     color: @textColor01;
            //     font-size: 14px;
            //     font-weight: 500;
            //     margin-bottom: 10px;
            // }
            // .copyrights{
            //     .eFont;
            //     font-size: 15px;
            //     color: @textColor01;
            // }
            address{
                max-width: 800px;
                font-style: normal;
                font-size: 14px;
                font-weight: 500;
                line-height: 1.57;
                color: @pointColor;
                margin: 0 auto 20px;
                a,span{
                    font-size: inherit;
                    font-weight: inherit;
                    line-height: inherit;
                    color: inherit;
                    white-space: nowrap;
                }
            }
            .ft_list{
                display: inline-block;
                li{
                    margin-right: 8px;
                    &::after{
                        content: '';
                        display: inline-block;
                        width: 1px;
                        height: 8px;
                        background-color: #e8e8e8;
                        margin-left: 8px;
                    }
                    &:last-child{
                        &::after{
                            .bld;
                        }
                    }
                    a{
                        font-size: 12px;
                        font-weight: bold;
                        color: @textColor03;
                    }
                }
            }
        }
        &.right{
            width: 210px;
            
        }
    }

}
/*popup*/
.popup{
    .bld;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(51, 51, 51, 0.87);
    z-index: 30;
    .popup_content{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        max-width: 80%;
        outline: 1px solid red;
    }
    &.img_gallery_popup{
        .popup_content{
            .cont_box{
                position: relative;
                .img_sld{
                    img{
                        display: block;
                        width: auto;
                        max-width: 100%;
                    }
                }
                .btn_close{
                    position: absolute;
                    right: 0;
                    top: -(18px + 10px);
                    width: 18px;
                    height: 18px;
                    background: url(/asset/images/icn-menu-close-white.png) no-repeat center/100%;
    
                }
                .btn_prev,.btn_next{
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 36px;
                    height: 36px;
                    .bgo;
                    background-size: 20px;
                }
                .btn_prev{
                    left: -(36px + 8px);
                    background-image: url(/asset/images/btn-w-pagination-line-arrow-white-prev-act.png);
                }
                .btn_next{
                    right: -(36px + 8px);
                    background-image: url(/asset/images/btn-w-pagination-line-arrow-white-next-act.png);
                }
                
            }
        }
    }

}
.layer_popup{
    .bld;   
    width: 100%;
    height: 100%;
    background-color: rgba(51, 51, 51, 0.86);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 100;
    .popup_contents{
        max-width: 1170px;
        width: 100%;
        position: absolute;
        left: 50%;
        top: 100px;
        transform: translateX(-50%);
        background-color: #fff;
        .btn_close{
            width: 44px;
            height: 44px;
            background: @grey03 url(/asset/images/icn-menu-close-navy.png) no-repeat center/18px;
            cursor: pointer; 
            position: absolute;
            right: 0;
            top: 0;
        }
        .title_box{
            padding: 73px 0;
            text-align: center;
            background: @pointColor url(/asset/images/img-ggc-popup-bg.png) no-repeat center/100%;
            p{
                font-size: 36px;
                font-weight: bold;
                color: white;
                word-break: keep-all;
            }
        }
        .contents_box{
            padding: 50px 17% 60px;
        }
    }
    &.signup_notice{
        .contents_box{
            .desc{
                margin-bottom: 35px;
                p{
                    font-weight: 500;
                    line-height: 1.75;
                }
            }
            .panel03{
                margin-bottom: 25px;
                &:last-of-type{
                    margin-bottom: 0;
                }
            }
            .benefits{
                .panel_cont{
                    font-size: 0;
                    ul{
                        .inline-block;
                        li{
                            margin-right: 20px;
                            &:nth-child(1){
                                .icon{
                                    background-image: url(/asset/images/pic-join-01.png);
                                }
                            }
                            &:nth-child(2){
                                .icon{
                                    background-image: url(/asset/images/pic-join-02.png);
                                }
                            }
                            &:nth-child(3){
                                .icon{
                                    background-image: url(/asset/images/pic-join-03.png);
                                }
                            }
                            &:nth-child(4){
                                .icon{
                                    background-image: url(/asset/images/pic-join-04.png);
                                }
                            }
                            &:last-child{
                                margin-right: 0;
                            }
                            .icon{
                                .inline-block;
                                width: 62px;
                                height: 62px;
                                .bgo;
                                background-size: 100%;
                            }
                            .text{
                                font-size: 14px;
                                font-weight: 500;
                                line-height: 1.43;
                                color: @pointColor;
                            }
                        }
                    }
                }
            }
            .application_form_guide{
                .panel_cont{
                    text-align: left;
                    padding-left: 30px;
                    padding-right: 30px;
                    dl{
                        float: none;
                        margin-bottom: 20px;
                        &.first-child{
                            dt{
                                margin-top: 13px;
                            }
                        }
                        &:last-child{
                            margin-bottom: 0;
                        }
                        .afters;
                        dt,dd{
                            float: left;
                            font-size: 15px;
                        }
                        dt{
                            width: 120px;
                            font-weight: 500;
                            margin-top: 2px;
                        }
                        dd{
                            width: calc(100% - 120px);
                            font-weight: 300;
                            ul{
                                li{
                                    float: none;
                                    font-size: 15px;
                                    font-weight: 300;
                                    padding-left: 5px;
                                    position: relative;
                                    margin-bottom: 5px;
                                    &:last-child{
                                        margin-bottom: 0;
                                    }
                                    &::before{
                                        content: '';
                                        display: block;
                                        width: 2px;
                                        height: 2px;
                                        background-color: @grey02;
                                        position: absolute;
                                        left: 0;
                                        top: 10px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .closing_ment{
                margin-top: 30px;
                font-size: 18px;
                font-weight: 500;
                text-align: center;
                .point{
                    color: @pointColor;
                    font-size: inherit;
                    font-weight: inherit;
                }
            }
        }
    }
    &.contents_request{
        .contents_box{
            .panel03{
                margin-bottom: 24px;
                &:last-child{
                    margin-bottom: 48px;
                }
                .panel_tit{
                    .title{
                        font-size: 24px;
                    }
                }
                &.form_download{
                    .panel_cont{
                        padding-left: 62px;
                        padding-right: 62px;
                        .category_list{
                            .inline-block;
                            >li{
                                width: 100%/3;
                                padding: 25px 28px;
                                text-align: center;
                                position: relative;
                                &::after{
                                    content: '';
                                    display: block;
                                    width: 1px;
                                    height: 135px;
                                    background-color: @grey03;
                                    position: absolute;
                                    right: 0;
                                    top: 25px;
    
                                }
                                &::before{
                                    content:'';
                                    display: block;
                                    width: 152px;
                                    height: 1px;
                                    background-color: @grey03;
                                    position: absolute;
                                    left: 28px;
                                    top: 0;
                                }
                                &:nth-child(3n){
                                    &::after{
                                        .bld;
                                    }
                                }
                                &:nth-child(-n+3){
                                    &::before{
                                        .bld;
                                    }
                                }
                                .category{
                                    font-weight: 500;
                                    color: @pointColor;
                                    margin-bottom: 10px;
                                }
                                .icon{
                                    .inline-block;
                                    width: 50px;
                                    height: 50px;
                                    margin-bottom: 5px;
                                    .bgo;
                                    &.display{
                                        background-image: url(/asset/images/pic-request-01.png);
                                        background-size: 48px;
                                    }
                                    &.play{
                                        background-image: url(/asset/images/pic-request-02.png);
                                        background-size: 35px;
                                    }
                                    &.event{
                                        background-image: url(/asset/images/pic-request-03.png);
                                        background-size: 42px;
                                    }
                                    &.education{
                                        background-image: url(/asset/images/pic-request-04.png);
                                        background-size: 50px;
                                    }
                                    &.news{
                                        background-image: url(/asset/images/pic-request-05.png);
                                        background-size: 36px;
                                    }
                                    &.modification_request{
                                        background-image: url(/asset/images/pic-request-06.png);
                                        background-size: 40px;
                                    }
                                }
                                .download_list{
                                    >li{
                                        float: none;
                                        margin-bottom: 5px;
                                        &:last-child{
                                            margin-bottom: 0;
                                        }
                                        a{
                                            display: block;
                                            font-size: 12px;
                                            font-weight: 500;
                                            letter-spacing: -.5px;
                                            &::before{
                                                content: '';
                                                display: inline-block;
                                                width: 18px;
                                                height: 18px;
                                                vertical-align: -4px;
                                                margin-right: 5px;
                                                .bgo;
                                                background-size: 100%;
                                                
                                            }
                                            &.hwp{
                                                &::before{
                                                    background-image: url(/asset/images/icn-download-hangul.png);
                                                }
                                            }
                                            &.docx{
                                                &::before{
                                                    background-image: url(/asset/images/icn-download-msword.png);
                                                }
                                            }
                                            &:hover{
                                                text-decoration: underline;
                                            }
                                        }
                                    }
                                }
                                 
                            }
                        }
                    }
                }
                &.form_upload{
                    .panel_cont{
                        padding-left: 73px;
                        padding-right: 73px;
                        dl{
                            float: none;
                            margin-bottom: 15px;
                            .afters;
                            &:last-of-type{
                                margin-bottom: 0;
                            }
                            dt,dd{
                                float: left;
                            }
                            dt {
                                width: 120px;
                                font-size: 15px;
                                font-weight: 500;
                                margin-top: 10px;
                                text-align: left;
                            }
                            dd{
                                width: calc(100% - 120px);
                                .file_upload_box{
                                    &.show{
                                        .input_box01{
                                            width: 100%;
                                        }
                                    }
                                }
                            }
                        }
                        .btn_area{
                            padding-top: 44px;
                            text-align: center;
                        }
                    }
                }
                
            }
            .precaution_box{
                background-color: @bgColor01;
                padding: 30px;
                .title{
                    font-size: 15px;
                    font-weight: 500;
                    margin-bottom: 10px;
                }
            }
        }
    }
    &.privacy_policy{
        .contents_box{
            max-height: 700px;
            overflow-y: auto;
            h2{
                font-size: 22px;
                font-weight: bold;
                color: @pointColor;
                margin-bottom: 10px;
                span{
                    font-size: 16px;
                    font-weight: 500;
                    color: inherit;
                }
            }
            >.desc{
                font-weight: 500;
                line-height: 1.75;
                margin-bottom: 40px;
                word-break: keep-all;
            }
            .g1{
                margin-bottom: 40px;
                h3{
                    font-weight: bold;
                    line-height: 1.38;
                    margin-bottom: 15px;
                }
                >.desc{
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 1.57;
                    margin-bottom: 10px;
                    word-break: keep-all;
                }
            }
        }   
    }
}

/*tour*/
.tour_box{
    background-color: @pointColor;
    position: relative;
    &::after{
        content: '';
        width: 100%;
        height: 74px;
        background-color: #fff;
        position: absolute;
        left: 0;
        bottom: 0;
    }
    .sc_title{
        padding-top: 26px;
        h2,p{
            color: white !important;
        }
    }
    .sc_cont{
        padding-top: 38px;
        position: relative;
        z-index: 1;
        .tour_search_box{
            max-width: 1090px;
            background-color: @grey03;
            padding: 26px 52px 40px 98px;
            margin: 0 auto;
            .location,.date,.btn_tour_search{
                .inline-block;
                vertical-align: middle;
            }
            dl{
                float: none;
                .inline-block;
                width: 200px;
                dt{
                    font-weight: bold;
                    color: @textColor01;
                    margin-bottom: 5px;
                }
            }
            .tour_input{
                width: 100%;
                height: 48px;
                .eFont;
                font-size: 22px;
                padding: 0 44px 0 15px;
                background: white url(/asset/images/icn-calandar-act.png) no-repeat right 15px center/14px;
                color: @pointColor;
                &::placeholder{
                    font-size: 20px;
                    font-weight: 300;
                    .fontA;
                    color: inherit;
                }
                
            }
            .location{
                margin-right: 40px;
            }
            .date{
                margin-right: 30px;
                dl{
                    position: relative;
                    &:first-child{
                        margin-right: 50px;
                        &::after{
                            content: '';
                            display: block;
                            width: 26px;
                            height: 4px;
                            .bgo;
                            background-image: url(/asset/images/icn-w-linearrow-ggctour-navy.png);
                            position: absolute;
                            right: -(26px + 12px);
                            bottom: 20px;
                        }
                    }
                }
            }
            .btn_tour_search{
                width: 210px;
                text-align: center;
                padding: 10px 0;
                background-color: @pointColor;
                cursor: pointer;
                vertical-align: bottom;
                span{
                    .eFont;
                    font-size: 20px;
                    color: #fff;
                    margin-right: 5px;
                    &::before{
                        content: '';
                        .inline-block;
                        width: 24px;
                        height: 24px;
                        .bgo;
                        background-image: url(/asset/images/zoom-white.png);
                        background-size: 24px;
                        margin-right: 8px;
                        vertical-align: middle;
                    }
                }
            }
        }
    }
}
.tour_box02{
    .afters;
    .left,.right{
        float: left;
    }
    .title{
        background-color: @pointColor;
        padding: 20px 16px 24px;
        width: 312px;
        p{
            .eFont;
            font-size: 28px;
            color: white;
            line-height: 1;
            .subtitle{
                letter-spacing: -.5px;
                color: inherit;
                display: inline-block;
                margin-left: 10px;
            }
        }
    }
    @nWidth: 22.5%;
    .tour_search_box{
        width: calc(100% - 312px);
        border-top: @border01;
        border-bottom: @border01;
        padding: 15px 0 15px 2.6%;
        .afters;
        .location,.date,.btn_tour_search{
            float: left;
        }
        .location,.date{
            margin-right: 2.6%;
        }
        .location{
            width: @nWidth
        }
        .date{
            width: calc(@nWidth*2 + 48px);
            .afters;
            >.input_box01{
                width: calc((100% - 48px)/2);
                float: left;
            }
            .start_date{
                margin-right: 48px;
                position: relative;
                &::after{
                    content: '';
                    display: block;
                    width: 26px;
                    height: 4px;
                    .bgo;
                    background-image: url(/asset/images/icn-w-linearrow-ggctour-navy.png);
                    position: absolute;
                    right: -(26px + 12px);
                    bottom: 20px;
                }
            }
        }
        .btn_tour_search{
            .tour_box > .sc_cont > .tour_search_box > .btn_tour_search;
            width: @nWidth;
            padding: 6px 0;
            @media screen and (max-width: 1450px) {
                width: 21.3%;
            }

        }
        
        
    }
}

/*no_result*/
.no_result_case{
    margin: 52px 0 78px;
    .guide_box{
        max-width: 870px;
        margin: 0 auto;
        border: @border02;
        padding: 96px 90px 96px 80px;
        .afters;
        .left,.right{
            float: left;
        }
        .left{
            width: 33.6%;
            .icon{
                width: 132px;
                height: 144px;
                .bgo;
                background-image: url(/asset/images/img-search-nodata.png);
            }
        }
        .right{
            width: 66.4%;
            .g_title{
                font-size: 26px;
                font-weight: 500;
                line-height: 1.23;
                word-break: keep-all;
                margin-bottom: 24px;
                .word{
                    font-size: inherit;
                    font-weight: bold;
                    line-height: inherit;
                    color: @pointColor;
                    &::before{
                        content: '‘';
                        font-size: inherit;
                        font-weight: inherit;
                        color: inherit;
                    }
                    &::after{
                        content: '’';
                        font-size: inherit;
                        font-weight: inherit;
                        color: inherit;
                    }
                }
            }
            .list01{
                li{
                    font-size: 15px;
                    &::before{
                        top: 10px;
                    }
                }
            }
        }
    }
}

/*-------------------------------------------------------------------
    Pages
-------------------------------------------------------------------*/
#contentsWrap{
    padding-top: 250px;//기본값
    opacity: 0;
    &.main_wrap{
        padding-bottom: 80px;
        section{
            margin-bottom: @mainSectionBottom;
            &:last-child{
                margin-bottom: 0;
            }
            .sc_title{
                text-align: center;
                h2{
                    .eFont;
                    font-size: 52px;
                    color: @pointColor;
                    margin-bottom: 5px;
                }
                .subtitle{
                    font-size: 20px;
                    font-weight: 300;
                    color: @pointColor;
                }
            }
        }
        .articles{
            padding-top: 40px;
            .row{
                .afters;
                margin-bottom: 40px;
                &:last-child{
                    margin-bottom: 0;
                }
                >div{
                    float: left;
                    margin-right: 2.7%;
                    &:last-child{
                        margin-right: 0;
                    }
                }
                .col7{
                    width: (100% - 2.7%)*0.65;
                }
                .col3{
                    width: (100% - 2.7%)*0.35;
                    .card_box01{
                        margin-bottom: 40px;
                        &:last-child{
                            margin-bottom: 0;
                        }
                    }
                }
                .col5{
                    width: (100% - 2.7%)*0.5;
                }
            }
        }
        .news{
            margin-bottom: @mainSectionBottom - 24px;
            .sc_cont{
                padding-top: 36px;
                .tab01_cont{
                    padding-top: 32px;
                    >div{
                        .afters;
                        .box01{
                            height: 192px;
                            float: left;
                            .list-width-multi-row (@listGap01; 3);
                            margin-bottom: 24px;
                        }
                    }
                }
            }
        }
        .on_going{
            .sc_cont{
                padding-top: 5px;
                .tab02_cont{
                    padding-top: 10px;
                    >div{
                        .swiper-slide{
                            .list-width-single-row(@listGap01; 4);
                        }
                        .contents_box{
                            border-top: @border-pointColor;
                            border-bottom: @border-pointColor;
                            padding-top: 60px;
                            .afters;
                            >div{
                                float: left;
                                height: 615px;
                                border-top: @border01;
                            }
                            .calendar{
                                width: 450px;
                                margin-right: 2%;
                                padding-top: 40px;
                                .calendar_header{
                                    margin-bottom: 30px;
                                    text-align: center;
                                    .btn_prev_month,.btn_next_month,.date{
                                        .inline-block;
                                        vertical-align: middle;
                                    }
                                    .date{
                                        .eFont;
                                        font-size: 32px;
                                        color: @pointColor;
                                        width: 210px;
                                    }
                                    .btn_prev_month,.btn_next_month{
                                        .navigationBtnCommon;
                                        cursor: pointer;
                                    }
                                    .btn_prev_month{
                                        .navigationPrev;
                                    }
                                    .btn_next_month{
                                        .navigationNext;
                                    }
                                }
                                .ui-datepicker{
                                    width: 100%;
                                    padding: 0 0 10px;
                                    border: none;
                                    .ui-widget-header{
                                        padding: 0 0 32px;
                                        background-color: white;
                                        .ui-datepicker-title{
                                            span{
                                                color: @pointColor;
                                                font-size: 32px;
                                            }
                                        }
                                        .ui-datepicker-next ,.ui-datepicker-prev{
                                            top: -5px;
                                        }
                                        .ui-datepicker-prev{
                                            left: 80px;
                                        }
                                        .ui-datepicker-next{
                                            right: 80px;
                                        }
                                    }
                                    .ui-datepicker-calendar{
                                        margin: 0;
                                        width: 100%;
                                        border: 1px solid #e8e8e8;
                                        thead{
                                            th{
                                                padding: 25px 12px 26px;
                                                span{
                                                    font-size: 13px;
                                                }
                                            }
                                        }
                                        tbody{
                                            td{
                                                padding: 10px 5px;
                                                .ui-state-default{
                                                    width: 48px;
                                                    height: 48px;
                                                    font-size: 21px;
                                                    padding: 13px 0;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            .list_boxes{
                                width: calc(100% - 450px - 2%);
                                padding: 10px; 
                                overflow-y: auto;
                                &::-webkit-scrollbar{
                                    width: 14px;
                                    background-color: @grey03;  
                                }
                                &::-webkit-scrollbar-thumb{
                                    background-color: @grey01;
                                    border-radius: 6px;
                                }
                            }

                        }
                    }
                }
            }
        }
        .ggc_guide{
            .sc_cont{
                padding-top: 30px;
                .keyword_box{
                    border: @border01;
                    margin-bottom: 40px;
                    padding: 18px 46px;
                    .afters;
                    .question{
                        float: left;
                        max-width: calc(100% - 90px);
                        font-size: 40px;
                        font-weight: 300;
                        letter-spacing: -.8px;
                        line-height: 1.3;
                        color: @pointColor;
                        .typing_box{
                            display: inline-block;
                            position: relative;
                            font-size: inherit;
                            letter-spacing: inherit;
                            line-height: inherit;
                            padding-right: 2px;
                            >span{
                                font-size: inherit;
                                font-weight: 500;
                                color: white;
                                letter-spacing: inherit;
                                line-height: inherit;
                            }
                            .typing_keyword{
                                opacity: 0;
                            }
                            .keyword{
                                background-color: @pointColor;
                                position: absolute;
                                left: 0;
                                top: 0;
                                &.on{
                                    display: inline-block; 
                                    animation-name: cursor; 
                                    animation-duration: 0.3s; 
                                    animation-iteration-count: infinite; 
                                }
                            }
                        }
                        
                    }
                    .btn_read_more{
                        float: right;
                        position: relative;
                        top: 16px;
                        &:hover{
                            .btn_read_more_hover;
                        }
                    }
                }
                .swiper-container{
                    padding-bottom: 80px - 32px;
                    .swiper-slide{
                        padding-right: 1.2%;
                        &:nth-child(3n){
                            margin-right: 0;
                        }
                        .card_box03{
                            margin-bottom: 32px;
                        }
                    }
                }
            }
        }
        .ggc_book{
            .sc_cont{
                padding-top: 40px;
                .sld_box_area{
                    max-width: 1170px;
                    margin: 0 auto;
                    position: relative;
                    .swiper-container{
                        max-width: 1050px;
                        padding-bottom: 0;
                        .swiper-slide{
                            width: 100%/4;
                            padding: 0 30px;
                        }    
                    }
                    .sld_prev,.sld_next{
                        position: absolute;
                        top: 32%;
                    }
                    .sld_prev{
                        left: 0;    
                    }
                    .sld_next{
                        right: 0;
                    }
                }
            }
        }
        .ggc_issue{
            // .sc_title{
                // .keyword_list{
                //     width: 300px;
                //     margin: 10px auto 0;
                //     border-top: @border01;
                //     border-bottom: @border01;
                //     position: relative;
                //     padding: 3px 0;
                //     .swiper-container{
                //         padding-bottom: 0;
                //         .swiper-slide{
                //             .keyword{
                //                 font-size: 20px;
                //                 font-weight: 300;
                //                 color: @pointColor;
                //             }
                //         }
                //     }
                //     .btn_sld_prev,.btn_sld_next{
                //         position: absolute;
                //         top: 0;
                //         width: 24px;
                //         height: 100%;
                //         .bgo;
                //         background-size: 8px;
                //         background-color: #fff;
                //         z-index: 1;
                //         cursor: pointer;
                //     }
                //     .btn_sld_prev{
                //         left: 0;
                //         .navigationPrev;
                //     }
                //     .btn_sld_next{
                //         right: 0;
                //         .navigationNext;
                //     }
                // }
            // }
            .sc_cont{
                 .keyword_list{
                    width: 300px;
                    margin: 10px auto 0;
                    border-top: @border01;
                    border-bottom: @border01;
                    position: relative;
                    padding: 3px 0;
                    text-align: center;
                    .keyword{
                        font-size: 20px;
                        font-weight: 300;
                        color: @pointColor;
                    }
                }
                .swiper-container{
                    padding-bottom: 80px - 32px;
                    .swiper-slide{
                        .sld_section{
                            padding-top: 24px;
                            .afters;
                            .card_box03{
                                margin-bottom: 32px;
                                float: left;
                                .list-width-multi-row(1.2%; 3);
                            }
                        }
                        // padding-right: 1.2%;
                        // &:nth-child(3n){
                        //     margin-right: 0;
                        // }
                    }
                }
               
                // .sld_box_area{
                //     padding-top: 24px;
                //     .afters;
                //     .card_box03{
                //         float: left;
                //         .list-width-multi-row(2.5%; 3);
                //         margin-bottom: 32px;
                //     }
                //     .paging_area{
                //         padding-top: 40px - 32px;
                //     }
                // }
                
            }
        }
        .inside_gyeonggido{
            margin-bottom: @mainSectionBottom - 20px;
            .sc_cont{
                padding-top: 46px;
                font-size: 0;
                .box02{
                    .list-width-multi-row(1%; 16);
                    margin-bottom: 16px;
                }
            }
        }
        .whats_new{
            .sc_cont{
                padding-top: 40px;
                display: flex;
                flex-wrap: wrap;
                .card_box05{
                    .list-width-multi-row(@listGap01; 4);
                    margin-bottom: 50px;
                }
            }
        }
        
    }
    &.sub_wrap{
        padding-bottom: 130px;
        .title_section{
            padding: 70px 0 78px;
            text-align: center;
            h2{
                font-size: 48px;
                font-weight: 500;
                letter-spacing: -1.2px;
                color: @pointColor;
                line-height: 1;
                text-transform: capitalize;
            }
        }
        .options_box{
            margin-bottom: 20px;
            .afters;
            .left{
                float: left;
            }
            .right{
                float: right;
            }
            .total_list{
                p{
                    font-size: 15px;
                    font-weight: 500;
                    letter-spacing: -.63px;
                    position: relative;
                    top: 16px;
                    >span{
                        .inline-block;
                        font-size: inherit;
                        font-weight: inherit;
                        letter-spacing: inherit;
                        color: @grey02;
                        margin-left: 5px;
                    }
                }
            }
            .options_select{
                display: flex;
                ul{
                    // .inline-block;
                    // vertical-align: middle;
                    margin-right: 15px;
                    li{
                        width: 160px;
                        margin-right: 10px;
                        &:last-child{
                            margin-right: 0;
                        }
                    }
                }
                .select_search_box{
                    display: flex;
                    // .inline-block;
                    // vertical-align: middle;
                    .btn_search{
                        // .inline-block;
                        // vertical-align: middle;
                        width: 40px;
                        height: 40px;
                        background: @pointColor url(/asset/images/icn-zoom.png) no-repeat center/22px;
                        vertical-align: -13px;
                    }
                }
            }
            .options_button{
                font-size: 0;
                >a{
                    width: 168px;
                    margin-right: 10px;
                    &:last-child{
                        margin-right: 0;
                    }
                }
            }
        }
        .paging_area{
            margin-top: 40px;
        }
        .btn_area{
            padding-top: 40px;
            font-size: 0;
            text-align: center;
        }
        &.post_list{
            .sld_list_section{
                margin-top: 50px;
                margin-bottom: 125px;
            }
            .archive_list_section{
                position: relative;
                .target_point{
                    display: block;
                    width: 1px;
                    height: 1px;
                    position: absolute;
                    left: 0;
                    top: -220px;
                }
                .options_box{
                    .afters;
                    margin-bottom: 24px;
                    ul{
                        float: right;
                        li{
                            width: 160px;
                            margin-right: 10px;
                            &:last-child{
                                margin-right: 0;
                            }
                            
                        }
                    }
                }
                .list_cont{
                    display: flex;
                    flex-wrap: wrap;
                    .card_box06{
                        .list-width-multi-row(@listGap01; 4);
                        margin-bottom: 50px;
                    }
                }
            }
        }
        &.post_list01{
            .sld_list_section{
                .tab01{
                    .inner{
                        width: 930px;
                        ul{
                            width: 100%;
                            li{
                                width: 50%;
                            }
                        }
                    }
                }
                .tab01_cont{
                    padding-top: 48px;
                    position: relative;
                    .top_btn_area{
                        text-align: right;
                        margin-bottom: 10px;
                    }
                    .swiper-container{
                        .swiper-wrapper{
                            .swiper-slide{
                                .list-width-single-row(@listGap01; 4);
                            }
                        }
                    }
                    .btn_register{
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        z-index: 1;
                    }
                }
            }
            .archive_list_section{
                .list_title{
                    text-align: center;
                    margin-bottom: 40px;
                    h3{
                        font-size: 40px;
                        font-weight: 500;
                        letter-spacing: -1px;
                        color: @pointColor;
                        line-height: 1;
                        margin-bottom: 10px;
                    }
                    p{
                        font-size: 20px;
                        font-weight: 300;
                        color: @pointColor;
                    }
                }
            }
        }
        &.post_list02{
            .sld_list_section{
                .swiper-container{
                    .swiper-wrapper{
                        .swiper-slide{
                            .list-width-single-row(@listGap01; 3);
                            .card_box06{
                                figcaption{
                                    .title{
                                        font-size: 34px;
                                        letter-spacing: -.9px;
                                        .line-clamp(1.18; 2);
                                    }
                                    .subtitle{
                                        font-size: 22px;
                                        font-weight: 300;
                                        letter-spacing: -.46px;
                                        .line-clamp(1.36; 2);
                                        white-space: normal;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &.tour{
                .tour_box02{
                    margin-bottom: 32px;
                }
            }
        }
        &.post_view{
            h3{
                font-size: 40px;
                font-weight: bold;
                color: @pointColor;
                margin-bottom: 30px;
            }
            h4{
                .eFont;
                font-size: 22px;
                letter-spacing: -.34px;
                color: @pointColor;
                margin-bottom: 20px;
            }
            .sns_box{
                text-align: center;
                ul{
                    .inline-block;
                    li{
                        margin-right: 10px;
                        &:last-child{
                            margin-right: 0;
                        }
                        a{
                            display: block;
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            background-color: @pointColor;
                            font-size: 0;
                            text-align: center;
                            .bgo;
                            &.twitter{
                                background-image: url(/asset/images/icn-article-sharetwt.png);
                                background-size: 21px;
                            }
                            &.facebook{
                                background-image: url(/asset/images/icn-article-sharefb.png);
                                background-size: 11px;
                            }
                            &.print{
                                background-image: url(/asset/images/icn-article-print.png);
                                background-size: 20px;
                            }
                            &.like{
                                background-image: url(/asset/images/icn-article-like-default.png);
                                background-position: top 10px center;
                                background-size: 14px;
                                position: relative;
                                span{
                                    font-size: 12px;
                                    color: #fff;
                                    .eFont;
                                    position: absolute;
                                    display: block;
                                    width: 100%;
                                    bottom: 4px;
                                }
                                &.on{
                                    background-image: url(/asset/images/icn-article-like-act.png);
                                }
                            }
                        }
                    }
                }
            }
        }
        &.post_view01{
            .post_view_area{
                .view_top{
                    border-top: @border-pointColor;
                    padding: 80px 0;
                    .title_box{
                        margin-bottom: 30px;
                        text-align: center;
                        .badge_box{
                            margin-bottom: 10px;
                            .badge{
                                display: inline-block;
                                padding: 3px 19px 5px;
                                min-width: 78px;
                                font-weight: 500;
                                color: #fff;
                                vertical-align: middle;
                                margin-right: 10px;
                                &:last-child{
                                    margin-right: 0;
                                }
                                &.ongoing{
                                    padding: 5px 19px 7px;
                                }
                            }
                        }
                        .post_title{
                            font-size: 48px;
                            font-weight: 300;
                            margin-bottom: 10px;
                            word-break: keep-all;
                        }
                        .post_date{
                            font-size: 24px;
                            font-weight: 300;
                        }
                    }
                    .img_box{
                        margin-bottom: 40px;
                        overflow: hidden;
                        img{
                            display: block;
                            margin: 0 auto;
                            height: 568px;
                        }
                    }
                }
                .view_bottom{
                    .info_box,.map_box{
                        border-top: @border01;
                    }
                    .info_box{
                        padding: 50px 0;
                        .cont{
                            .afters;
                            .left,.right{
                                float: left;
                                width: 50%;
                                dl{
                                    float: none;
                                    margin-bottom: 10px;
                                    .afters;
                                    &:last-child{
                                        margin-bottom: 0;
                                    }
                                    dt,dd{
                                        float: left;
                                    }
                                    dt{
                                        width: 68px;
                                        font-size: 14px;
                                        font-weight: bold;
                                    }
                                    dd{
                                        width: calc(100% - 68px);
                                        font-size: 14px;
                                        font-weight: 300;
                                        word-break: keep-all;
                                        a{
                                            font-size: inherit;
                                            font-weight: inherit;
                                            &:hover{
                                                text-decoration: underline;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .map_box{
                        padding: 80px 0 110px;
                        text-align: center;
                        .map{
                            position: relative;
                            padding-top: 57.6%;
                            margin-bottom: 10px;
                            iframe{
                                width: 100%;
                                height: 100%;
                                position: absolute;
                                left: 0;
                                top: 0;
                            }
                        }
                        .address{
                            .inline-block;
                            float: none;
                            .afters;
                            dt,dd{
                                float: left;
                                font-size: 14px;
                                font-weight: bold;
                            }
                        }
                        
                    }
                }
            }
            .gallery_area{
                padding: 45px 0;
                background-color: @bgColor01;
                text-align: center;
                .sld_box_area{
                    position: relative;
                    .swiper-container{
                        padding-bottom: 0;
                        .swiper-wrapper{
                            .swiper-slide{
                                width: auto;
                                margin-right: 30px;
                                &:last-child{
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                    .sld_next,.sld_prev{
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%); 
                    }
                    .sld_prev{
                        left: -(40px + 30px);
                    }
                    .sld_next{
                        right: -(40px + 30px);
                    }
                }
                
            }
            .recommend_area{
                padding-top: 70px;
                text-align: center;
                .swiper-container{
                    .swiper-wrapper{
                        .swiper-slide{
                            .list-width-single-row(@listGap02; 4);
                        }
                    }
                }
            }
        }
        &.post_view02{
            .post_view_area{
                padding-top: 72px;
                border-bottom: @border01;
                margin-bottom: 70px;
                .post_top{
                    .img_box{
                        margin-bottom: 60px;
                        .img{
                            padding-top: 48.5%;
                            .bgo;
                            background-color: lightgrey;
                        }
                    }
                    .title_box{
                        margin-bottom: 60px;
                        text-align: center;
                        >p{
                            line-height: 1;
                        }
                        .group{
                            font-size: 18px;
                            font-weight: 500;
                            color: @pointColor;
                            margin-bottom: 10px;
                        }
                        .title{
                            font-size: 48px;
                            font-weight: 300;
                            margin-bottom: 15px;
                            word-break: keep-all;
                        }
                        .subtitle{
                            font-size: 24px;
                            font-weight: 300;
                        }
                    }
                }
                .post_mid{
                    border-top: @border01;
                    padding: 80px 0 130px;
                    position: relative;
                    // .afters;
                    // .left,.right{
                    //     float: left;
                    // }
                    .post_box{
                        // width: 73%;
                        width: calc(100% - 310px);
                        padding: 0 28px;
                        .post_view_txt_section;
                        .copyright_phrase{
                            margin-top: 40px;
                            font-size: 15px;
                            font-weight: 500;
                            color: #4a4a4a;
                            text-align: center;
                        }
                    }
                    .info_box{
                        width: 310px;
                        padding-top: 80px;
                        position: absolute;
                        top: 0;
                        bottom: auto;
                        left: 50%;
                        background-color: #fff;
                        margin-left: 305px;
                        @media screen and (max-width: 1290px) {
                            margin-left: 24%;
                        }
                        &.sticky{
                            position: fixed;
                            top: 108px;
                            bottom: auto;
                        }
                        &.stop{
                            position: absolute;
                            top: auto;
                            bottom: 100px;
                        }

                        // width: 27%;
                        // padding: 0 3.4% 0 5%;
                        .row{
                            margin-bottom: 30px;
                            &:last-child{
                                margin-bottom: 0;
                            }
                            h4{
                                margin-bottom: 10px;
                            }
                            .cont{
                                font-size: 0;
                                >dl{
                                    float: none;
                                    margin-bottom: 10px;
                                    &:last-child{
                                        margin-bottom: 0;
                                    }
                                    dt{
                                        font-size: 14px;
                                        font-weight: bold;
                                        margin-bottom: 5px;
                                    }
                                    dd{
                                        font-size: 14px;
                                        font-weight: 300;
                                        line-height: 1.57;
                                        >a{
                                            font-size: inherit;
                                            font-weight: inherit;
                                            line-height: inherit;
                                            &:hover{
                                                text-decoration: underline;
                                            }
                                        }
                                    }
                                }
                                >ul{
                                    li{
                                        float: none;
                                        margin-bottom: 10px;
                                        &:last-child{
                                            margin-bottom: 0;
                                        }
                                        .ui-sortable-handle{
                                            font-size: 14px;
                                            font-weight: 300;
                                            line-height: 1.57;
                                            margin-bottom: 10px;
                                            &:last-child{
                                                margin-bottom: 0;
                                            }
                                            span{
                                                display: block;
                                                font-size: 14px;
                                                font-weight: bold;
                                            }
                                            >a{
                                                font-size: inherit;
                                                font-weight: inherit;
                                                line-height: inherit;
                                                &:hover{
                                                    text-decoration: underline;
                                                }
                                            }
                                        }
                                    }
                                }
                                .pebble_box{
                                    .inline-block;
                                    padding: 3px 7px;
                                    border-radius: 12px;
                                    background-color: #e8e8e8;
                                    margin-right: 5px;
                                    margin-bottom: 5px;
                                    &:last-child{
                                        margin-right: 0;
                                    }
                                    span{
                                        font-size: 12px;
                                        font-weight: 500;
                                        &::before{
                                            font-size: inherit;
                                            font-weight: inherit;
                                            display: inline;
                                        }
                                    }
                                }
                                .hashtag{
                                    margin-bottom: 10px;
                                    .pebble_box{
                                        span{
                                            &::before{
                                                content: '#';
                                            }
                                        }
                                    }
                                }
                                .at{
                                    .pebble_box{
                                        span{
                                            &::before{
                                                content: '@';
                                            }
                                        }
                                    }
                                }
                            }
                            &.sns_box{
                                text-align: left;
                            }
                        }
                    }
                }
                .post_bot{
                    width: calc(100% - 310px);
                    // width: 73%;
                    border-top: @border01;
                    padding: 30px 0;
                    .afters;
                    .left,.right{
                        float: left;
                    }
                    .img_box{
                        width: 144px;
                        padding: 10px;
                        border: 1px solid @grey02;
                        .img{
                            width: 100%;
                            padding-top: 100%;
                            .bgo;
                            background-color: lightgrey;
                        }
                    }
                    .right{
                        width: calc(100% - 144px);
                        padding-left: 30px;
                        dl{
                            float: none;
                            margin-bottom: 10px;
                            .afters;
                            dt,dd{
                                float: left;
                            }
                            dt{
                                font-weight: bold;
                                color: @pointColor;
                                margin-right: 5px;
                                &::after{
                                    content: '/';
                                    font-weight: inherit;
                                    color: inherit;
                                }
    
                            }
                            dd{
                                font-weight: 500;
                            }
                        }
                    }
                }
            }
            .recommend_area{
                .row{
                    margin-bottom: 60px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                    h3{
                        text-align: center;
                    }
                    .sld_box_area{
                        .afters;
                        .card_box06{
                            float: left;
                            .list-width-single-row(@listGap01; 4);
                        }
                    }
                }
            }
        }
        &.search_result{
            .wrap02{
                padding-top: 52px;
            }
            .panel01{
                margin-bottom: 84px;
                &:last-child{
                    margin-bottom: 0;
                }
                .panel_cont{
                    .list_boxes{
                        border-top: @border-pointColor;
                        border-bottom: @border-pointColor;
                        padding: 10px 0;
                        margin-bottom: 40px;
                        .list_box02{
                            &:last-child{
                                border-bottom: none;
                            }
                        }
                    }
                    
                }
            }
        }
        &.culture_events{
            .tab_cont{
                padding-top: 50px;
                .lists_box{
                    display: flex;
                    flex-wrap: wrap;
                    .card_box02{
                        .list-width-multi-row(@listGap01; 4);
                        margin-bottom: 40px;

                    }
                }
            }
        }
        &.news_list{
            .tab_cont{
                .tab_title_box{
                    padding: 70px 0 60px;
                    text-align: center;
                    h3{
                        font-size: 32px;
                        font-weight: 500;
                        line-height: 1;
                    }
                }
                .lists_box{
                    .table01{
                        .tr{
                            .th,.td{
                                &:nth-child(1){
                                    width: 8.9%;
                                }
                                &:nth-child(2){
                                    width: 65%;
                                }
                                &:nth-child(3){
                                    width: 8.9%;
                                }
                                &:nth-child(4){
                                    width: 10.5%;
                                }
                                &:nth-child(5){
                                    width: 6.7%;
                                }
                            }
                        }
                    }
                }
                .paging_area{
                    position: relative;
                    .btn_register{
                        position: absolute;
                        right: 16px;
                        bottom: 0;
                    }
                }
            }
        }
        &.news_view{
            .post_view_area{
                border-top: @border-pointColor;
                border-bottom: @border-pointColor;
                .title_box{
                    padding: 60px 0 70px;
                    border-bottom: @border01;
                    text-align: center;
                    .title_list{
                        &::after{
                            content: '';
                            display: block;
                            width: 20px;
                            height: 1px;
                            background-color: #333;
                            margin: 20px auto;
                        }
                        .badge_box{
                            margin-bottom: 10px;
                            .badge{
                                .inline-block;
                                padding: 4px 5px;
                                min-width: 42px;
                                font-size: 12px;
                                color: #fff;
                                line-height: 1;
                                text-align: center;
                                margin-right: 8px;
                                &:last-child{
                                    margin-right: 0;
                                }
                            }
                        }
                        .post_title{
                            font-size: 48px;
                            font-weight: 300;
                            line-height: 1.25;
                            margin-bottom: 5px;
                            word-break: keep-all;
                        }
                        .group{
                            font-size: 24px;
                            font-weight: 300;
                        }   
                    }
                    .info_list{
                        .inline-block;
                        li{
                            margin-right: 30px;
                            position: relative;
                            &::after{
                                content: '';
                                display: block;
                                width: 1px;
                                height: 8px;
                                background-color: #e8e8e8;
                                position: absolute;
                                right: -14px;
                                top: 50%;
                                transform: translateY(-50%);
                            }
                            &:last-child{
                                margin-right: 0;
                                &::after{
                                    .bld;
                                }
                            }
                            dl{
                                float: none;
                                .afters;
                                dt,dd{
                                    float: left;
                                }
                                dt{
                                    font-size: 15px;
                                    font-weight: 500;
                                    letter-spacing: 1px;
                                    padding-right: 10px;
                                    margin-top: 2px;
                                }
                                dd{
                                    font-weight: 300;
                                }
                                &.period{
                                    dd{
                                        color: @pointColor;
                                        font-weight: 500;
                                    }
                                }
                            }

                        }
                    }

                }
                .post_view_box{
                    padding: 65px 0 70px;
                    .post_box{
                        .post_view_txt_section;
                    }
                    .cf_file_box{
                        border: @border02;
                        margin-top: 40px;
                        .afters;
                        .left,.right{
                            float: left;
                        }
                        .title{
                            padding: 30px 36px;
                            font-size: 15px;
                            font-weight: 500;
                        }
                        .download_list{
                            padding: 16px 30px; 
                            width: calc(100% - 128px);
                            .btn_file_download{
                                margin-bottom: 8px;
                                &:last-child{
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
        &.article{
            .search_box01{
                max-width: @maxWidth03;
                border: @border02;
                padding: 36px 0;
                text-align: center;
                margin: 0 auto 50px;
                >div{
                    .inline-block;
                    .options_box>.options_select;
                }
            }
            .tab01{
                .inner{
                    ul{
                        li{
                            width: 155px;
                        }
                    }
                }
            }
            .tab_cont{
                padding-top: 56px;
                .lists_box{
                    .table02{
                        .th,.td{
                            &:nth-child(1){
                                width: 8.2%;
                            }
                            &:nth-child(2){
                                width: 7.3%;
                            }
                            &:nth-child(3){
                                width: 56.9%;
                            }
                            &:nth-child(4){
                                width: 9.7%;
                            }
                            &:nth-child(5){
                                width: 6.3%;
                            }
                            &:nth-child(6){
                                width:11.6%;
                            }
                        }
                    }
                }
            }
        }
        &.write_form{
            .tab_cont{
                padding-top: 50px;
                .form_box{
                    padding: 50px 0 64px;
                    .row{
                        margin-bottom: 25px;
                        .afters;
                        .col{
                            float: left;
                            width: (100% - 4.6%)/2;
                            margin-right: 4.6%;
                            &:nth-child(even){
                                margin-right: 0;
                            }
                        }
                        dl{
                            float: none;
                            dt{
                                margin-bottom: 5px;
                                position: relative;
                                >span{
                                    display: inline-block;
                                    font-weight: 500;
                                    color: #3a3a3a;
                                }
                                &.ls_wide{
                                    >span{
                                        letter-spacing: 4.27px;
                                    }
                                }
                                .cmt{
                                    font-size: 12px;
                                    font-weight: bold;
                                    color: @pointColor;
                                    letter-spacing: normal;
                                    margin-left: 5px;
                                }
                                .required_cmt{
                                    font-size: 12px;
                                    font-weight: 500;
                                    color: @textColor04;
                                    position: absolute;
                                    right: 0;
                                    top: 0;
                                    &::before{
                                        content: '*';
                                        font-weight: 500;
                                        color: @red02;
                                    }
                                }
                            }
                            dd{
                                margin-bottom: 15px;
                                &:last-child{
                                    margin-bottom: 0;
                                }
                                &.period{
                                    .afters;
                                    .input_box01{
                                        float: left;
                                        position: relative;
                                        width: calc((100% - 33px) / 2);
                                    }
                                    .start{
                                        margin-right: 32px;
                                        &::after{
                                            content: '';
                                            display: block;
                                            width: 8px;
                                            height: 1px;
                                            background-color: @grey02;
                                            position: absolute;
                                            right: -20px;
                                            top: 19px;
                                        }
                                    }
                                }
                                &.multi{
                                    .afters;
                                    >.input_box01{
                                        float: left;
                                        width: calc((100% - 13px)/2);
                                        &:first-child{
                                            margin-right: 12px;
                                        }
                                    }
                                }
                            }
                            &.required{
                                dt{
                                    &::after{
                                        content: '*';
                                        font-weight: 500;
                                        color: @red02;
                                    }
                                }
                            }
                        }
                    }
                    .agreement_box{
                        border: @border02;
                        padding: 33px 38px;
                        .all_check_box{
                            margin-bottom: 10px;
                            .check_box02{
                                text-indent: -4px;
                            }
                        }
                        .check_boxes{
                            font-size: 0;
                            >div{
                                .inline-block;
                                margin-right: 20px;
                                &:last-child{
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                    .precaution_box{
                        border: @border02;
                        padding: 30px;
                        .title{
                            font-size: 15px;
                            font-weight: 500;
                            margin-bottom: 10px;
                        }
                    } 
                }
            }
        }
        &.introduce{
            .title_section{
                margin-top: 40px;
                padding: 97px 0 103px;
                background: url(/asset/images/img-ggc-intro-bg@2x.jpg) no-repeat center/100%;
                h2{
                    color: white;
                }
            }
            .contents_section{
                border-top: @border-pointColor;
                padding-top: 80px;
                .panel02{
                    margin-bottom: 54px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
                .ggc_introduce{
                    .panel_cont{
                        p{
                            margin-bottom: 10px;
                            &:last-child{
                                margin-bottom: 0;
                            }
                        }
                    }
                }
                .ggc_service{
                    .panel_cont{
                        padding-top: 20px;
                        .afters;
                        .left,.right{
                            float: left;
                            .img_box{
                                text-align: center;
                                font-size: 0;
                            }
                        }
                        .left{
                            width:  45%;
                        }
                        .right{
                            width:  55%;
                        }
                    }
                }
                .ggc_logo{
                    .panel_cont{
                        padding-top: 15px;
                        .list_box03{
                            margin-bottom: 45px;
                            &:last-child{
                                margin-bottom: 0;
                            }
                            .right{
                                font-size: 0;
                                .btn_download{
                                    margin-right: 16px;
                                    &:last-child{
                                        margin-right: 0;
                                    }
                                }
                            }
                        }    
                    }
                } 
            }
        }
        &.open_api{
            .tab01{
                .inner{
                    width: @maxWidth03;
                    ul{
                        width: 100%;
                        li{
                            width: 50%;
                        }
                    }
                }
            }
            .tab_title_box02{
                padding: 75px 0 30px;
                text-align: center;
                h3{
                    font-size: 24px;
                    font-weight: bold;
                    color: @pointColor;
                    margin-bottom: 8px;
                }
                .subtitle{
                    font-weight: 500;
                    line-height: 1.63;
                    color: @textColor04;
                }
            }
            .btn_area{
                display: flex;
                justify-content: flex-end;
                padding-top: 0;
                margin-bottom: 16px;
            }
            .panel05{
                margin-bottom: 40px;
                &:last-of-type{
                    margin-bottom: 0;
                }
            }
        }
    }
}


/*-------------------------------------------------------------------
    Lang : en
-------------------------------------------------------------------*/
.en{
    header{
        .top_gnb_area{
            >div{
                >ul{
                    margin-right: 0;
                }
            }
        }
        .main_gnb_area{
            nav{
                ul{
                    li{
                        a{
                            .eFont;
                        }
                    }
                }
            }
        }
        .site_map{
            height: 520px;
            .wrap01{
                .group1{
                    a{
                        margin-bottom: 25px;
                        span{
                            .eFont;
                            font-weight: 400;
                            font-size: 34px;
                        }
                        &.impt{
                            span{
                                font-weight: 900;
                            }
                        }
                    }
                }
                .group3{
                    dl{
                        dt{
                            a{
                                .eFont;
                                // font-weight: 400;
                                font-size: 32px;
                            }
                        }
                    }
                }
            }
        }
    }
    footer{
        .g2{
            &.left{
                address{
                    max-width: 500px;
                }
            }
        }
    }
    .card_box01{
        a{
            figcaption{
                .subtitle{
                    line-height: 1.5;
                    margin-bottom: 10px;
                }
            }
            &.ver1{
                figcaption{
                    .group{
                        margin-bottom: 0;
                    }
                    .title{
                        line-height: 1.5;
                        margin-bottom: 5px;        
                    }
                }
            }
        }
    }
    .card_box03{
        a{
            figcaption{
                .subtitle{
                    white-space: normal;
                    .line-clamp(1.38; 3)
                }
            }
        }
    }
    .card_box05{
        a{
            figcaption{
                .title{
                    .line-clamp(1.23; 3)
                }
                .subtitle{
                    white-space: normal;
                    .line-clamp(1.56; 2)
                }
            }
        }
    }
    .panel02{
        .panel_tit{
            .eFont;
        }
    }
    .tour_box02{
        .title{
            text-align: center;
        }
    }
    #contentsWrap{
        &.sub_wrap{
            .title_section{
                h2{
                    .eFont;
                }
            }
            &.introduce{
                .title_section{
                    h2{
                        text-transform: none;
                    }
                }
                .contents_section{
                    .ggc_introduce{
                        .panel_cont{
                            p{
                                margin-bottom: 0;
                                &.bold{
                                    font-size: 19px;
                                    font-weight: bold;
                                    span{
                                        &.eFont{
                                            font-size: 26px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .ggc_service{
                        .panel_cont{
                            .col{
                                margin-bottom: 15px;
                                .afters;
                                &:last-child{
                                    margin-bottom: 0;
                                }
                                .left,.right{
                                    float: left;
                                    height: 192px;
                                }
                                .left{
                                    width: 24%;
                                    img{
                                        display: block;
                                        height: 100%;
                                    }
                                }
                                .right{
                                    width: 76%;
                                    display: flex;
                                    justify-content: center;
                                    flex-direction: column;
                                }
                            }
                        }
                    }
                }   
            }
        }
    }
}


/*-------------------------------------------------------------------
    Keyframes
-------------------------------------------------------------------*/
@keyframes cursor{ 
    0%{border-right: 2px solid #fff} 
    50%{border-right: 2px solid @pointColor} 
    100%{border-right: 2px solid #fff} 
}
